import { ADMIN_ROLE, EMPLOYEE_ROLE, SUPPORT_ROLE } from "../constants/roles";
import OrdersComponent from "../containers/Orders";

const OrderDetailsRoute = {
  exact: true,
  name: "Order Details",
  protected: true,
  path: "/orders/:order_id",
  component: OrdersComponent,
  permissions: [EMPLOYEE_ROLE, SUPPORT_ROLE, ADMIN_ROLE],
};

export default OrderDetailsRoute;
