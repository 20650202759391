import Iconbutton from "../../components/common/Inputs/IconButton";
import { useAppSelector } from "../../redux/hooks";
import { selectOrder } from "../../redux/orders/reducer";
import Loader from "../../components/common/Loader";
import ErrorMessage from "../../components/common/ErrorMessage";
import OrderData from "../../components/Orders/OrderInfo";
import Label from "../../components/common/Label";
import parseStatus from "../../helpers/parseStatus";
import getStatusColor from "../../helpers/getStatusColor";
import { selectUser } from "../../redux/user/reducer";
import { MdOutlineAssignmentInd } from "react-icons/md";

type Props = {
  theme: string;
  handleAssignButtonClick: any;
  handleOrderClick: any;
};

const OrdersList: React.FC<Props> = ({
  theme,
  handleAssignButtonClick,
  handleOrderClick,
}) => {
  const {
    orders: { loading, error, data },
  } = useAppSelector(selectOrder);

  const { currentUser } = useAppSelector(selectUser);

  const body = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage message={error} />;

    if (data.length === 0) {
      return <div>No Orders Yet</div>;
    }

    return data.map((item) => (
      <div
        className="flex flex-col gap-y-3 orders_container__item border-b border-[#EEEEEE] py-6 px-2 last-of-type:border-none hover:bg-[#eeeeee] cursor-pointer transition-all duration-200l ease-linear lg:py-8 lg:flex-row lg:items-end lg:w-full lg:justify-between"
        key={item.id}
      >
        <div
          className="flex flex-col gap-y-2 lg:gap-y-4 orders_container__item__left"
          onClick={() => handleOrderClick(item.id, item)}
        >
          <div className="orders_container__item__left__order_id">
            <p className="flex items-center gap-x-2 text-[#555555] text-[13px] lg:text-[15px] orders_container__item__left__order_id__title">
              Order ID{" "}
              <span className="text-[15px] orders_container__item__left__order_id__title__id">
                #{item.id}
              </span>
              <Label
                title={parseStatus(item.status)}
                type={getStatusColor(item.status)}
              />
            </p>
          </div>

          <OrderData order={item} />
        </div>

        {["admin", "support"].includes(currentUser.user_role) && (
          <div className="orders_container__item__right">
            <Iconbutton onClick={() => handleAssignButtonClick(item.id)}>
              <MdOutlineAssignmentInd className="text-[36px] text-[#AAAAAA]" />
            </Iconbutton>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={`flex flex-col orders_container ${theme}`}>{body()}</div>
  );
};

export default OrdersList;
