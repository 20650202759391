import React from "react";
import "./index.scss";

type Props = {
  type?: "text" | "number" | "password";
  placeholder?: string;
  name: string;
  value: string | number;
  onChange: any;
  error?: boolean;
  errorMessage?: string;
  largeField?: boolean;
  className?: string;
  inputClassName?: string;
};

const TextInput: React.FC<Props> = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  error = false,
  errorMessage = "",
  largeField = false,
  className,
  inputClassName,
}) => {
  return (
    <div
      className={`${className} input_container ${
        error && errorMessage && "show_error"
      }`}
    >
      {largeField ? (
        <textarea
          className={`input_container__input ${largeField}`}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
        />
      ) : (
        <input
          type={type}
          className={`${inputClassName} input_container__input`}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
        />
      )}
      {error && errorMessage && (
        <p className="input_container__error_message">{errorMessage}</p>
      )}
    </div>
  );
};

export default TextInput;
