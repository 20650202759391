import React, { useEffect, useRef } from "react";
import "./index.scss";
import SearchIcon from "../../../../assets/icons/search_gray.png";
import TriangleIcon from "../../../../assets/icons/triangle_down_gray.png";
import { OptionValues } from "../../../../types/shared";

type Props = {
  // For the search
  placeholder: string;
  name: string;
  value: string;
  onSearchChange: any;

  // For the filter
  selected: string;
  values: OptionValues[];
  onFilterChange: any;
  openDropdown: boolean;
  setOpenDropdown: any;
};

const SearchWithFilter: React.FC<Props> = ({
  // For the search
  placeholder,
  name,
  value,
  onSearchChange,

  // For the filter
  selected,
  values,
  onFilterChange,
  openDropdown,
  setOpenDropdown,
}) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        if (openDropdown) setOpenDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown, ref, setOpenDropdown]);

  return (
    <div className="search_input_with_filter_container card">
      <div
        className="search_input_with_filter_container__search_dropdown_container"
        onClick={() => setOpenDropdown(!openDropdown)}
        // @ts-ignore
        ref={ref}
      >
        <p className="search_input_with_filter_container__search_dropdown_container__title">
          {selected}
        </p>
        <img src={TriangleIcon} alt="Drop Down Icon" />

        <div
          className={`search_input_with_filter_container__search_dropdown_container__values card ${
            openDropdown && "open_dropdown"
          }`}
        >
          {values.map((item, index) => (
            <p
              key={`${item}_${index}`}
              className={`search_input_with_filter_container__search_dropdown_container__values__value ${
                selected === item.title && "active"
              }`}
              onClick={() => onFilterChange(item)}
            >
              {item.title}
            </p>
          ))}
        </div>
      </div>

      <img src={SearchIcon} className="search_input_icon" alt="Search Icon" />

      <input
        type="text"
        className="search_input_with_filter_container__input"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onSearchChange(e)}
      />
    </div>
  );
};

export default SearchWithFilter;
