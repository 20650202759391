import "./index.scss";
import { IOrder } from "../../types/order";
import Label from "../common/Label";
import parseStatus from "../../helpers/parseStatus";
import getStatusColor from "../../helpers/getStatusColor";
import {
  FiChevronRight,
  FiArrowLeft,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { useState } from "react";

type Props = {
  title: string;
  handleBackClick?: any;
  showOrderDetails?: boolean;
  order?: IOrder | undefined;
  menuActions?: React.ReactNode;
  children: any;
};

const Header: React.FC<Props> = ({
  title,
  handleBackClick,
  showOrderDetails,
  order,
  menuActions,
  children,
}) => {
  const [showMoreMenuActions, setShowMoreMenuActions] = useState(false);

  return (
    <div className="header_containerr flex flex-col items-center gap-y-2 lg:flex-row lg:h-[60px]">
      <div className="header_containerr__title flex items-center gap-x-2 w-full lg:w-auto md:gap-x-4">
        {showOrderDetails && (
          <FiArrowLeft
            className="text-[#AAAAAA] text-[24px] hover:cursor-pointer hover:text-primary"
            onClick={handleBackClick}
          />
        )}
        <div className="flex items-center gap-x-2 md:gap-x-3">
          {title}
          {showOrderDetails && (
            <>
              <FiChevronRight
                className="text-[#cccccc] text-[20px]"
                onClick={handleBackClick}
              />
              <span className="header_containerr__title__id">{order?.id}</span>
              {order && (
                <Label
                  title={parseStatus(order.status)}
                  type={getStatusColor(order.status)}
                />
              )}
            </>
          )}
        </div>

        <div className="flex items-center gap-x-1 md:gap-x-3 lg:hidden m-auto mr-0">
          {menuActions}

          {children &&
            (showMoreMenuActions ? (
              <FiChevronUp
                className="text-[#AAAAAA] text-[32px]"
                onClick={() => setShowMoreMenuActions(false)}
              />
            ) : (
              <FiChevronDown
                className="text-[#AAAAAA] text-[32px]"
                onClick={() => setShowMoreMenuActions(true)}
              />
            ))}
        </div>
      </div>

      <div
        className={`header_containerr__contentt ${
          showMoreMenuActions ? "flex" : "hidden"
        } flex-col gap-x-3 w-full m-auto mr-0 lg:flex lg:flex-row lg:items-center lg:justify-end lg:w-auto`}
      >
        <div className="hidden lg:flex">{menuActions}</div>
        {children}
      </div>
    </div>
  );
};

export default Header;
