import Modal from "../../components/common/Modal";
import CustomOptionSelector from "../../components/common/Inputs/CustomOptionSelector";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";
import { IUser } from "../../types/user";
import { formatDate } from "../../helpers/parseDateAndTime";
import { HiUser } from "react-icons/hi2";

type Props = {
  showModal: boolean;
  setShowModal: any;
  userID: any;
};

const UserDetailsModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  userID,
}) => {
  const {
    users: { data },
  } = useAppSelector(selectUser);
  const [user, setUser] = useState({} as IUser | undefined);

  useEffect(() => {
    const selectedUser = data.find((u) => u.id === userID);
    setUser(selectedUser);
  }, [userID, setUser, data]);

  const handleUserStatusChange = (value: string) => {};

  const handleUserTypeChange = (value: string) => {};

  return (
    <Modal
      header="User Details"
      showModal={showModal}
      setShowModal={setShowModal}
      underline={false}
      size="medium"
    >
      <div className="user_detail_container">
        <div className="user_detail_container__left">
          <HiUser className="text-[64px] text-[#888888] mb-[20px]" />

          <div className="user_detail_container__left__details">
            <div className="user_detail_label">Name</div>
            <div className="user_detail_value">
              {user?.first_name}&nbsp;{user?.last_name}
            </div>
            <div className="user_detail_label">Email</div>
            <div className="user_detail_value">{user?.email}</div>
            <div className="user_detail_label">Phone Number</div>
            <div className="user_detail_value">{user?.phone_number}</div>
            <div className="user_detail_label">Address</div>
            <div className="user_detail_value">{user?.address}</div>
          </div>
        </div>
        <div className="user_detail_container__right">
          <div className="user_detail_container__right__user_type flex-col overflow-x-auto md:flex-row">
            <span>User Type</span>
            <CustomOptionSelector
              options={[
                { title: "User", value: "user" },
                { title: "Admin", value: "admin" },
                { title: "Employee", value: "employee" },
                { title: "Support", value: "support" },
              ]}
              active={user?.user_role ?? "user"}
              handleCustomOptionSelectorClick={handleUserTypeChange}
            />
          </div>
          <div className="user_detail_container__right__user_status flex-col overflow-x-auto md:flex-row">
            <span>User Status</span>
            <CustomOptionSelector
              options={[
                { title: "Active", value: "active" },
                { title: "Suspended", value: "suspended" },
                { title: "Blocked", value: "blocked" },
              ]}
              active={user?.status ?? "active"}
              handleCustomOptionSelectorClick={handleUserStatusChange}
            />
          </div>
          <div className="user_detail_container__right__joindate">
            <div className="user_detail_container__right__joindate__label">
              Joined on
            </div>
            <div className="user_detail_container__right__joindate__value">
              {formatDate(user?.created_at ?? "")}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
