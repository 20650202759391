import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import orderReducer from "./orders/reducer";
import questionReducer from "./questions/reducer";
import promoCodeReducer from "./promoCodes/reducer";

const reducers = combineReducers({
  user: userReducer,
  order: orderReducer,
  promo_code: promoCodeReducer,
  question: questionReducer,
});

export default reducers;
