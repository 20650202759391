import LoginComponent from "../containers/Login";

const LoginRoute = {
  exact: true,
  name: "Login",
  protected: false,
  path: "/login",
  component: LoginComponent,
  permissions: [],
};

export default LoginRoute;
