import { TLocalStorageData } from "../../types/localStorage";

const { REACT_APP_HASTE_LAUNDRY_SETTINGS = "_haste_laundry_settings_" } =
  process.env;

const getFromLocalStorage = (): TLocalStorageData => {
  if (typeof window !== "undefined") {
    const data = localStorage.getItem(REACT_APP_HASTE_LAUNDRY_SETTINGS);

    // FIXME: Add a function here that checks the values there were returned from the localstorage, and if the values are not exactly what we expect, then we take what are correct, and replace the rest.

    if (!data) return { authenticated: false, language: "en" };

    return JSON.parse(data) as TLocalStorageData;
  }

  return { authenticated: false, language: "en" };
};

export default getFromLocalStorage;
