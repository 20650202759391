import "./index.scss";

type Props = {
  hasBackground?: boolean;
  size?: "small" | "medium" | "large";
};

const HastleLaundryLogo = ({
  hasBackground = false,
  size = "small",
}: Props) => {
  return (
    <div
      className={`nav_logo ${hasBackground ? "white_background" : ""} ${size}`}
    >
      <div className={"nav_logo__top"}>
        <span>H</span>
        <span>A</span>
        <span>S</span>
        <span>T</span>
        <span>E</span>
      </div>
      <span className={"nav_logo__bottom"}>LAUNDRY</span>
    </div>
  );
};

export default HastleLaundryLogo;
