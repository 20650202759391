import React, { useCallback, useEffect } from "react";
import "./index.scss";

type Props = {
  header: string;
  children: any;
  footer?: string;
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  size?: "small" | "medium" | "large" | "xlarge";
  underline?: boolean;
};

const Modal: React.FC<Props> = ({
  header,
  children,
  footer,
  showModal,
  setShowModal,
  size = "medium",
  underline = false,
}) => {
  const escFunction = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    },
    [setShowModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    const modal = document.querySelector<HTMLDialogElement>("[data-modal]");

    if (showModal) modal?.showModal();
    // else modal?.close();

    return () => modal?.close();
  }, [showModal]);

  const body = () => {
    return (
      <dialog className="modal" data-modal>
        <div className={`modal_container ${size}`}>
          <div
            className={`modal_header_container ${underline && "header-border"}`}
          >
            <p className="modal_header_title">{header}</p>
            <span
              className="modal_header_close_button"
              onClick={() => setShowModal(!showModal)}
            >
              &times;
            </span>
          </div>
          <div className="modal_body_container">{children}</div>
          {footer && (
            <div className="modal_footer_container">
              <p className="modal_footer_text">{footer}</p>
            </div>
          )}
        </div>
      </dialog>
    );
  };
  return showModal ? body() : null;
};

export default Modal;
