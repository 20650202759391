import { MdOutlineAssignmentInd } from "react-icons/md";

type Props = {
  orderAssignStatus: string | undefined;
  handleAssignButtonClick: any;
  orderID: number | undefined;
};

const AssignButton: React.FC<Props> = ({
  orderAssignStatus,
  handleAssignButtonClick,
  orderID,
}) => {
  return (
    <div
      className={`assign_button_container ${
        orderAssignStatus === "unassigned" ? "unassigned" : "reassign"
      }`}
      onClick={() => handleAssignButtonClick(orderID)}
    >
      <MdOutlineAssignmentInd className="text-[24px] text-[#FFFFFF]" /> 
      <p className="assign_button_container__title">
        {orderAssignStatus === "unassigned" ? "Assign" : "Reassign"} Order
      </p>
    </div>
  );
};

export default AssignButton;
