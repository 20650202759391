import "./index.scss";

type Props = {
  title: string;
  type: "success" | "danger" | "info" | "warning";
};

const Label: React.FC<Props> = ({ title, type }) => {
  return (
    <span className={`label-${type}`}>{title}</span>
  );
};

export default Label;
