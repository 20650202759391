import axios from "axios";
import getFromLocalStorage from "./localStorage/getFromLocalStorage";

const responseHandler = (response: any) => response.data;
const errorHandler = (error: any) => {
  const errorResponse: any = error.response
    ? error.response.data
    : error.message;
  return Promise.reject(errorResponse);
};

const server = axios.create({
  baseURL: process.env.REACT_APP_HASTE_LAUNDRY_API_URL,
  headers: {
    platform: "HASTE_LAUNDRY_DASHBOARD",
    language: getFromLocalStorage().language,
  },
  withCredentials: true,
});

server.interceptors.response.use(responseHandler, errorHandler);

export default server;
