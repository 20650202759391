import Label from "../common/Label";
import "./index.scss";
import { IUser } from "../../types/user";
import { HiUser } from "react-icons/hi2";
import { HiBadgeCheck, HiPhone, HiOutlineMail } from "react-icons/hi";

type Props = {
  user: IUser;
};

const UserData: React.FC<Props> = ({ user }) => {
  return (
    <div className="flex items-center user_row gap-3">
      <div className="user_row__avatar">
        <HiUser className="text-[32px] text-[#888888]" />
      </div>
      <div className="flex flex-col gap-y-2 items-start user_row__info">
        <div className="user_row__info__name text-[16px] flex items-center gap-3">
          {`${user.first_name} ${user.last_name}`}
          {["admin", "support", "employee"].includes(user.user_role || "") && (
            <Label title={user.user_role || ""} type="info" />
          )}
        </div>
        <div className="flex flex-col gap-y-2 md:flex-row md:gap-x-8 user_row__info__contact">
          <div className="hidden md:flex user_row__info__contact__id md:max-w-[60px] md:w-[60px]">
            <span className="font-thin">#</span>
            <span className="font-semibold px-1 truncate">{user.id}</span>
          </div>
          <div className="flex items-center gap-x-2 text-[14px] text-[#888888] font-light md:text-[16px] md:max-w-[200px] md:w-[200px] user_row__info__contact__phone">
            <HiPhone className="text-[20px] text-[#888888]" />
            <span className="truncate">{user.phone_number}</span>
            {user.phone_verified && (
              <HiBadgeCheck className="text-[#4caf50] text-[20px] m-auto mr-0" />
            )}
          </div>
          <div className="flex items-center gap-x-2 text-[14px] text-[#888888] font-light md:text-[16px] md:max-w-[320px] md:w-[320px] user_row__info__contact__email">
            <HiOutlineMail className="text-[20px] text-[#888888]" />
            <span className="truncate">{user.email}</span>
            {user.email_verified && (
              <HiBadgeCheck className="text-[#4caf50] text-[20px] m-auto mr-0" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
