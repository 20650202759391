import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

import {
  login,
  getUserInfo,
  searchUsers,
  searchEmployees,
  logout,
  getUsers,
} from "./actions";
import { IUser } from "../../types/user";
import getFromLocalStorage from "../../helpers/localStorage/getFromLocalStorage";

const isAuthenticated = getFromLocalStorage().authenticated;

export type UserState = {
  isAuthenticated: boolean;
  currentUser: any;
  login: {
    error: string | null;
    loading: boolean;
  };
  getUserInfo: {
    loading: boolean;
    error: string | null;
  };
  searchEmployees: {
    loading: boolean;
    error: string | null;
    data: IUser[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
  users: {
    loading: boolean;
    error: string | null;
    data: IUser[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
  logout: {
    error: string | null;
    loading: boolean;
  };
};

const initialState: UserState = {
  isAuthenticated,
  currentUser: {},
  login: {
    loading: false,
    error: null,
  },
  getUserInfo: {
    loading: false,
    error: null,
  },
  searchEmployees: {
    loading: false,
    error: null,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
  users: {
    loading: false,
    error: null,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
  logout: {
    loading: false,
    error: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Login
    builder
      .addCase(login.pending, (state) => {
        state.login.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.login.loading = false;
        state.login.error = null;
        delete payload.password;
        state.isAuthenticated = true;
        state.currentUser = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.login.loading = false;
        state.login.error = payload as string;
      });

    // Search Users
    builder
      .addCase(searchUsers.pending, (state) => {
        state.users.loading = true;
        state.users.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.users.loading = false;
        state.users.error = null;
        state.users.data = payload.data;
        state.users.meta = payload.meta;
      })
      .addCase(searchUsers.rejected, (state, { payload }) => {
        state.users.loading = false;
        state.users.error = payload as string;
        state.users.data = initialState.users.data;
        state.users.meta = initialState.users.meta;
      });

    // Get User info
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.getUserInfo.loading = true;
        state.getUserInfo.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.getUserInfo.loading = false;
        state.getUserInfo.error = null;

        state.currentUser = payload;
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.currentUser = {};
        state.isAuthenticated = false;
        state.getUserInfo.loading = false;
        state.getUserInfo.error = payload as string;
      });

    // Logout
    builder
      .addCase(logout.pending, (state) => {
        state.logout.loading = true;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.logout.loading = false;
        state.logout.error = null;

        state.isAuthenticated = false;
        state.currentUser = initialState.currentUser;
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.logout.loading = false;
        state.logout.error = payload as string;

        state.isAuthenticated = false;
        state.currentUser = initialState.currentUser;
      });

    // Get Users
    builder
      .addCase(getUsers.pending, (state) => {
        state.users.loading = true;
        state.users.error = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users.loading = false;
        state.users.error = null;
        state.users.data = payload.data;
        state.users.meta = payload.meta;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.users.loading = false;
        state.users.error = payload as string;
        state.users.data = initialState.users.data;
        state.users.meta = initialState.users.meta;
      });

    // Search Employee
    builder
      .addCase(searchEmployees.pending, (state) => {
        state.searchEmployees.loading = true;
        state.searchEmployees.error = null;
      })
      .addCase(searchEmployees.fulfilled, (state, { payload }) => {
        state.searchEmployees.loading = false;
        state.searchEmployees.error = null;
        state.searchEmployees.data = payload.data;
        state.searchEmployees.meta = payload.meta;
      })
      .addCase(searchEmployees.rejected, (state, { payload }) => {
        state.searchEmployees.loading = false;
        state.searchEmployees.error = payload as string;
        state.searchEmployees.data = initialState.searchEmployees.data;
        state.searchEmployees.meta = initialState.searchEmployees.meta;
      });
  },
});

export const selectUser = (state: RootState) => state.user;

// export const {  } = userSlice.actions;

export default userSlice.reducer;
