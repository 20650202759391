import PERMISSIONS from "../../constants/permissions";

const canViewResource = (context: string, role: string) => {
  const { overview, orders, newOrders, users, questions, promoCodes } =
    PERMISSIONS.canViewResource;

  const roles = {
    [overview.context]: overview.roles,
    [orders.context]: orders.roles,
    [newOrders.context]: newOrders.roles,
    [users.context]: users.roles,
    [questions.context]: questions.roles,
    [promoCodes.context]: promoCodes.roles,
  };

  return roles[context]?.includes(role) || false;
};

export default canViewResource;
