import { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import SortIcon from "../../assets/icons/sort_gray.png";
import DropdownIcon from "../../components/common/Inputs/DropdownIcon";
import Pagination from "../../components/Pagination";
import { OptionValues } from "../../types/shared";
import SearchWithFilter from "../../components/common/Inputs/SearchWithFilter";
import IconButton from "../../components/common/Inputs/IconButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers, searchUsers } from "../../redux/user/actions";
import { selectUser } from "../../redux/user/reducer";
import {
  HiOutlineFilter,
  // HiOutlineSortAscending
} from "react-icons/hi";

type Props = {
  sortBy: string;
  setSortBy: any;
  setShowFilterModal: any;
  filterUsers: Record<string, string>;
};
const UsersHeader: React.FC<Props> = ({
  setShowFilterModal,
  sortBy,
  setSortBy,
  filterUsers,
}) => {
  const didMount = useRef(false);
  const dispatch = useAppDispatch();
  const {
    users: {
      meta: { total },
    },
  } = useAppSelector(selectUser);

  // Sort related state
  const [openSortDropdown, setOpenSortDropdown] = useState(false);

  const handleSortInput = (value: string) => {
    setSortBy(value);
  };

  // Pagination Related state
  const [paginationPages, setPaginationPages] = useState([1, 2, 3, 4]);
  const [selectedPageLimit, setSelectedPageLimit] = useState({
    title: "50 / Page",
    value: "50",
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const [openPaginationDropdown, setOpenPaginationDropdown] = useState(false);

  const handlePageLimitChange = (value: OptionValues) => {
    setSelectedPageLimit(value);
  };

  const handlePageChange = (value: number) => {
    setSelectedPage(value);
  };

  // Search User related
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchColumn, setSearchColumn] = useState<OptionValues>({
    title: "Name",
    value: "name",
  });

  const handleSearchFilterInput = (value: OptionValues) => {
    setSearchColumn(value);
  };

  const handleSearchInput = async ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchValue(value);
  };

  useEffect(() => {
    let findUsers: any;
    if (didMount.current) {
      findUsers = setTimeout(() => {
        if (searchValue === "") dispatch(getUsers({ sortBy, filterUsers }));

        if (searchValue.length < 2) return;

        const queryData: Record<string, string> = {};
        queryData[searchColumn.value] = searchValue;
        dispatch(searchUsers({ ...queryData, sortBy, filterUsers }));
      }, 1000);
    } else didMount.current = true;
    return () => clearTimeout(findUsers);
  }, [searchValue]); // eslint-disable-line

  return (
    <div className="users_page_header_container">
      <Header title={`All Users: ${total}`}>
        <DropdownIcon
          icon={SortIcon}
          className="sort_by_dropdown"
          values={[
            { title: "Name Ascending", value: "first_name__ASC" },
            { title: "Name Descending", value: "first_name__DESC" },
            { title: "Joined Date Ascending", value: "id__ASC" },
            { title: "Joined Date Descending", value: "id__DESC" },
          ]}
          selected={sortBy}
          onChange={handleSortInput}
          openDropdown={openSortDropdown}
          setOpenDropdown={setOpenSortDropdown}
        />

        <SearchWithFilter
          name="search_users"
          placeholder="Search"
          value={searchValue}
          onSearchChange={handleSearchInput}
          values={[
            { title: "Name", value: "name" },
            { title: "Phone Number", value: "phone_number" },
            { title: "Email", value: "email" },
          ]}
          selected={searchColumn.title}
          onFilterChange={handleSearchFilterInput}
          openDropdown={openSearchDropdown}
          setOpenDropdown={setOpenSearchDropdown}
        />

        <Pagination
          selectedLimit={selectedPageLimit.title}
          limitValues={[
            { title: "50 / Page", value: "50" },
            { title: "150 / Page", value: "150" },
            { title: "250 / Page", value: "250" },
            { title: "350 / Page", value: "350" },
            { title: "500 / Page", value: "500" },
          ]}
          onLimitChange={handlePageLimitChange}
          selectedPage={selectedPage}
          pageValues={paginationPages}
          onPageChange={handlePageChange}
          updatePageValues={setPaginationPages}
          openDropdown={openPaginationDropdown}
          setOpenDropdown={setOpenPaginationDropdown}
          maxPage={0}
        />

        <IconButton onClick={() => setShowFilterModal(true)}>
          <HiOutlineFilter className="text-[36px] text-[#AAAAAA]" />
        </IconButton>
      </Header>
    </div>
  );
};
export default UsersHeader;
