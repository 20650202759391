import React, { useEffect, useRef } from "react";
import "./index.scss";
import { OptionValues } from "../../../../types/shared";

type Props = {
  icon: string;
  selected: string;
  values: OptionValues[];
  onChange: any;
  openDropdown: boolean;
  setOpenDropdown: any;
  className?: string;
};

const DropdownIcon: React.FC<Props> = ({
  icon,
  selected,
  values,
  onChange,
  openDropdown,
  setOpenDropdown,
  className,
}) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        if (openDropdown) setOpenDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown, ref, setOpenDropdown]);

  return (
    <div
      className={`dropdown_with_icon_container card ${className}`}
      onClick={() => setOpenDropdown(!openDropdown)}
      // @ts-ignore
      ref={ref}
    >
      <img src={icon} alt="Drop Down Icon" />

      <div
        className={`dropdown_with_icon_container__values card ${
          openDropdown && "open_dropdown"
        }`}
      >
        {values.map((item, index) => (
          <p
            key={`${item}_${index}`}
            className={`dropdown_with_icon_container__values__value ${
              selected === item.value && "active"
            }`}
            onClick={() => onChange(item.value)}
          >
            {item.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default DropdownIcon;
