import { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import SearchWithFilter from "../../components/common/Inputs/SearchWithFilter";
import Modal from "../../components/common/Modal";
import { OptionValues } from "../../types/shared";
import Loader from "../../components/common/Loader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";
import ErrorMessage from "../../components/common/ErrorMessage";
import { searchEmployees } from "../../redux/user/actions";
import TextInput from "../../components/common/Inputs/TextInput";
import { assignOrder } from "../../redux/orders/actions";
import { selectOrder, resetAssignOrderState } from "../../redux/orders/reducer";
import UserData from "../../components/UserData";

type Props = {
  showModal: boolean;
  setShowModal: any;
  orderID: number | undefined;
};

const AssignModal: React.FC<Props> = ({ showModal, setShowModal, orderID }) => {
  // Search Transaction state
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchColumn, setSearchColumn] = useState<OptionValues>({
    title: "Name",
    value: "name",
  });

  const [showTab, setShowTab] = useState(1);

  const [userToAssign, setUserToAssign] = useState<number>();

  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSearchFilterInput = (value: OptionValues) => {
    setSearchColumn(value);
  };

  const handleSearchInput = async ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchValue(value);
  };

  const dispatch = useAppDispatch();

  const {
    searchEmployees: { loading, error, data },
  } = useAppSelector(selectUser);
  const {
    assignOrder: {
      loading: assignOrderLoading,
      error: assignOrderError,
      message,
    },
  } = useAppSelector(selectOrder);

  useEffect(() => {
    let findUsers: any;
    if (searchValue.length > 2) {
      findUsers = setTimeout(() => {
        const queryData: Record<string, string | number | boolean> = {};
        queryData[searchColumn.value] = searchValue;
        queryData["assign"] = true;
        dispatch(searchEmployees(queryData));
      }, 1000);
    }
    return () => clearTimeout(findUsers);
  }, [searchValue]); //eslint-disable-line

  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setConfirmPassword(value);
  };

  const handleUserSelect = (userID: number) => {
    setUserToAssign(userID);
  };

  const handleOrderAssign = () => {
    const data: Record<string, any> = {
      orderID,
      body: {
        user_id: userToAssign,
        password: confirmPassword,
      },
    };

    dispatch(assignOrder(data));
  };

  const body = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage message={error} />;

    if (data.length === 0)
      return (
        <div className="assign_modal_container__search_section__content__no_users">
          No User Found
        </div>
      );

    return data.map((item) => (
      <div
        key={item.id}
        onClick={() => handleUserSelect(item.id)}
        className={`assign_modal_container__search_section__content__users ${
          userToAssign === item.id ? "selected" : ""
        }`}
      >
        <UserData user={item} />
      </div>
    ));
  };

  if (message) {
    setTimeout(() => {
      dispatch(resetAssignOrderState());
      setShowTab(1);
      setUserToAssign(undefined);
      setConfirmPassword("");
      setSearchValue("");
      setShowModal(false);
    }, 1500);
  }

  return (
    <Modal
      header="Assign Order"
      showModal={showModal}
      setShowModal={setShowModal}
      size="large"
      underline
    >
      <div className="assign_modal_container">
        <div
          className={`assign_modal_container__search_section ${
            showTab === 1 && "active_section"
          }`}
        >
          <p className="assign_modal_container__search_section__info">
            Search user below, and select him/her to assign this order.
          </p>
          <SearchWithFilter
            name="search_users"
            placeholder="Search"
            value={searchValue}
            onSearchChange={handleSearchInput}
            values={[
              { title: "Name", value: "name" },
              { title: "Phone Number", value: "phone_number" },
              { title: "Email", value: "email" },
            ]}
            selected={searchColumn.title}
            onFilterChange={handleSearchFilterInput}
            openDropdown={openSearchDropdown}
            setOpenDropdown={setOpenSearchDropdown}
          />

          <div className="assign_modal_container__search_section__content">
            {body()}
          </div>
        </div>

        <div
          className={`assign_modal_container__confirm_section ${
            showTab === 2 && "active_section"
          }`}
        >
          <p className="assign_modal_container__confirm_section__info">
            Please confirm your password below to proceed.
          </p>

          <TextInput
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={confirmPassword}
          />

          <div className="assign_modal_container__confirm_section__feedback">
            {assignOrderError && <ErrorMessage message={assignOrderError} />}
            {message && <p>{message}</p>}
          </div>
        </div>

        <div className="assign_modal_container__actions">
          {showTab === 2 && (
            <Button
              type="danger"
              onClick={() => {
                setShowTab(1);
              }}
              title="Back"
            />
          )}

          {showTab === 2 ? (
            <Button
              type="primary"
              disabled={confirmPassword.length === 0 || !!message}
              loader={assignOrderLoading}
              onClick={handleOrderAssign}
              title="Assign"
            />
          ) : (
            <Button
              type="primary"
              disabled={[0, undefined].includes(userToAssign)}
              onClick={() => {
                setShowTab(2);
              }}
              title="Next"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignModal;
