import { IOrder } from "../../types/order";

import { formattedDateAndTime } from "../../helpers/parseDateAndTime";

type Props = {
  order: IOrder;
};

const OrderInfo: React.FC<Props> = ({
  order: {
    amount,
    pickup_year,
    pickup_month,
    pickup_date,
    pickup_hour,
    pickup_minute,
    delivery_year,
    delivery_month,
    delivery_date,
    delivery_hour,
    delivery_minute,
    pickup_and_delivery_address,
  },
}) => {
  return (
    <div className="flex flex-col gap-y-3 w-full items-start lg:flex-row order_list_data_container">
      <div className="flex flex-col lg:border-r border-[#aaaaaa] lg:pr-6 xl:pr-12">
        <p className="text-[#888888] font-light text-[12px] lg:text-[14px]">
          Est. Price
        </p>
        <p className="font-normal text-black text-[14px] lg:text-[16px]">
          ${amount}
        </p>
      </div>

      <div className="flex items-center justify-between w-full lg:w-auto">
        <div className="flex flex-col lg:border-r border-[#aaaaaa] lg:pr-6 xl:pr-12 lg:pl-6 xl:pl-9">
          <p className="text-[#888888] font-light text-[12px] lg:text-[14px]">
            Pickup Date
          </p>
          <p className="font-normal text-black text-[14px] lg:text-[16px]">
            {formattedDateAndTime({
              year: pickup_year,
              month: pickup_month - 1,
              date: pickup_date,
              hour: pickup_hour,
              minute: pickup_minute,
            })}
          </p>
        </div>

        <div className="flex flex-col lg:border-r border-[#aaaaaa] lg:pr-6 xl:pr-12 lg:pl-6 xl:pl-9">
          <p className="text-[#888888] font-light text-[12px] lg:text-[14px]">
            Delivery Date
          </p>
          <p className="font-normal text-black text-[14px] lg:text-[16px]">
            {formattedDateAndTime({
              year: delivery_year,
              month: delivery_month - 1,
              date: delivery_date,
              hour: delivery_hour,
              minute: delivery_minute,
            })}
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:pr-6 xl:pr-12 lg:pl-6 xl:pl-9">
        <p className="text-[#888888] font-light text-[12px] lg:text-[14px]">
          Address
        </p>
        <p className="font-normal text-black text-[14px] lg:text-[16px]">
          {pickup_and_delivery_address}
        </p>
      </div>
    </div>
  );
};

export default OrderInfo;
