import getFromLocalStorage from "./getFromLocalStorage";

const { REACT_APP_HASTE_LAUNDRY_SETTINGS = "_haste_laundry_settings_" } =
  process.env;

const setAuthData = (value: boolean) => {
  if (typeof window !== "undefined") {
    const data = getFromLocalStorage();

    data.authenticated = value;

    localStorage.setItem(
      REACT_APP_HASTE_LAUNDRY_SETTINGS,
      JSON.stringify(data)
    );
  }
};

export default setAuthData;
