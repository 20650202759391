import { ADMIN_ROLE, SUPPORT_ROLE } from "../constants/roles";
import PromoCodesComponent from "../containers/PromoCodes";

const PromoCodesRoute = {
  exact: true,
  name: "Promo Codes",
  protected: true,
  path: "/promo_codes",
  component: PromoCodesComponent,
  permissions: [ADMIN_ROLE, SUPPORT_ROLE],
};

export default PromoCodesRoute;
