import { useLocation, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";
import { logout } from "../../redux/user/actions";
import HasteLaundryLogo from "../common/Logo";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";
import routes from "../../routes";

type Props = {};

const NavBar: React.FC<Props> = () => {
  const {
    currentUser: { user_role },
  } = useAppSelector(selectUser);

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);

  const handleActivePage = (currentPage: string) => {
    const page = location.pathname;
    switch (page) {
      case currentPage:
        return "flex justify-center relative md:after:active-dot scale-[1.2] font-medium ml-[6px] md:ml-0";
      default:
        return "font-extralight";
    }
  };

  const {
    logout: { loading },
  } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const getNavBarRoutes = (): Array<{ name: string; path: string }> => {
    return routes
      .filter(
        ({ name, permissions }) =>
          !["Login", "Order Details"].includes(name) &&
          permissions.includes(user_role)
      )
      .map(({ name, path }) => {
        return {
          name,
          path,
        };
      });
  };

  return (
    <div className="fixed flex items-center justify-between px-4 gap-x-4 w-full h-[70px] bg-primary md:gap-x-8 z-[99]">
      <FiMenu
        className="text-[24px] text-white md:hidden"
        onClick={() => setShowMenu((prevState) => !prevState)}
      />

      <HasteLaundryLogo />

      <div
        className={`${
          showMenu
            ? "flex flex-col !h-auto gap-y-8 pt-4 pb-8 px-4 items-start"
            : "hidden"
        } absolute left-0 top-[70px] h-[60px] md:flex-row md:p-0 md:items-center justify-center gap-x-8 bg-primary w-full md:flex md:static md:justify-start md:gap-x-10`}
      >
        {getNavBarRoutes().map((route) => (
          <Link to={route.path} key={route.path}>
            <div
              className={`text-white text-[14px] md:text-[16px] hover:scale-[1.1] transition-all duration-200 ease-linear ${handleActivePage(
                route.path
              )}`}
            >
              {route.name}
            </div>
          </Link>
        ))}
      </div>

      <div
        className="text-white text-[14px] md:text-[16px] hover:scale-[1.1] transition-all duration-200 ease-linear mr-3"
        role="button"
        tabIndex={0}
        onClick={handleLogout}
      >
        Logout
        {loading && <div className="loader"></div>}
      </div>
    </div>
  );
};

export default NavBar;
