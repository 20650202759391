import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { selectUser } from "./redux/user/reducer";
import { getUserInfo } from "./redux/user/actions";
import Loader from "./components/common/Loader";
import routes from "./routes";
import ProtectedRoute from "./components/ProtectedRoutes";

// const Login = lazy(() => import("./containers/Login"));
// const Home = lazy(() => import("./containers/Overview"));
// const Users = lazy(() => import("./containers/Users"));
// const Orders = lazy(() => import("./containers/Orders"));
// const NewOrders = lazy(() => import("./containers/NewOrders"));

const protectedRoutes = (routes || []).filter((route) => route.protected);
const unprotectedRoutes = (routes || []).filter((route) => !route.protected);

function App() {
  const {
    isAuthenticated,
    currentUser,
    getUserInfo: { loading },
  } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && Object.keys(currentUser).length === 0 && !loading) {
      dispatch(getUserInfo());
    }
  }, [currentUser, dispatch, isAuthenticated, loading]);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {unprotectedRoutes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          ))}

          <Route
            element={
              <ProtectedRoute isAllowed={isAuthenticated} loading={loading} />
            }
          >
            {protectedRoutes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
