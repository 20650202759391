import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getQuestions } from "./actions";
import { IUser } from "../../types/user";

export type QuestionState = {
  questions: {
    loading: boolean;
    error: string | null;
    data: IUser[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
};

const initialState: QuestionState = {
  questions: {
    loading: false,
    error: null,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Users
    builder
      .addCase(getQuestions.pending, (state) => {
        state.questions.loading = true;
        state.questions.error = null;
      })
      .addCase(getQuestions.fulfilled, (state, { payload }) => {
        state.questions.loading = false;
        state.questions.error = null;
        state.questions.data = payload.data;
        state.questions.meta = payload.meta;
      })
      .addCase(getQuestions.rejected, (state, { payload }) => {
        state.questions.loading = false;
        state.questions.error = payload as string;
        state.questions.data = initialState.questions.data;
        state.questions.meta = initialState.questions.meta;
      });
  },
});

export const selectQuestion = (state: RootState) => state.question;

// export const {  } = questionSlice.actions;

export default questionSlice.reducer;
