import { useEffect } from "react";
import useTheme from "../../hooks/useTheme";
import "./index.scss";
import Layout from "../../components/Layouts/Default";
import PERMISSIONS from "../../constants/permissions";
import canViewResource from "../../helpers/permissions/canViewResource";
import { useNavigate } from "react-router-dom";
import OrdersRoute from "../../routes/orders";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";

const Overview: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [theme, readTheme, updateTheme] = useTheme();

  const {
    currentUser: { user_role },
  } = useAppSelector(selectUser);

  useEffect(() => {
    readTheme();
  }, [readTheme]);

  useEffect(() => {
    if (user_role) {
      if (
        !canViewResource(
          PERMISSIONS.canViewResource.overview.context,
          user_role
        )
      )
        navigate(OrdersRoute.path);
    }
  }, [navigate, user_role]);

  return (
    <Layout>
      <div className={`home_container ${theme}`}>
        <p className="home_container__text">Hello There!</p>

        <button
          className="home_container__action"
          onClick={() => {
            updateTheme();
          }}
        >
          Change Theme
        </button>
      </div>
    </Layout>
  );
};

export default Overview;
