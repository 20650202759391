import dayjs from "dayjs";
const objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);

export const parseDate = (year: number, month: number, day: number) => {
  return dayjs(`${year}-${month}-${day}`).format("DD MMM YYYY");
};

export const parseTime = (hour: number, minute: number) => {
  let formattedTime = "";

  if (hour > 12) formattedTime += `${hour - 12}:${minute} PM`;
  else formattedTime += `${hour}:${minute} AM`;

  if (hour < 10 || hour > 12) formattedTime = "0" + formattedTime;

  if (minute === 0) formattedTime = formattedTime.replace(" ", "0 ");

  return formattedTime;
};

export const formatDate = (
  date: string,
  format: string = "DD - MMM - YYYY"
) => {
  return dayjs(date).format(format);
};

export const formattedDateAndTime = ({
  year,
  month,
  date,
  hour,
  minute,
}: {
  year: number;
  month: number;
  date: number;
  hour: number;
  minute: number;
}) => {
  // @ts-ignore
  return dayjs({
    year,
    month,
    day: date,
    hour,
    minute,
  }).format("DD MMM YY - hh:mm A");
};
