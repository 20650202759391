import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../helpers/axios";

export const getQuestions = createAsyncThunk<any, any>(
  "question/getQuestions",
  async (
    {
      sortBy,
      filterUsers: { userType, userStatus },
    }: {
      sortBy: string;
      filterUsers: { userType: string; userStatus: string };
    },
    { rejectWithValue }
  ) => {
    const parsedQuery = `sortBy=${sortBy}&user_role=${userType}&status=${userStatus}`;

    try {
      const response = await server.get(`/users?${parsedQuery}`);
      if (response.status === 200) return response;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      rejectWithValue(message);
    }
  }
);
