import { ADMIN_ROLE, EMPLOYEE_ROLE, SUPPORT_ROLE } from "./roles";

const PERMISSIONS = {
  canViewResource: {
    users: {
      context: "users",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
    overview: {
      context: "overview",
      roles: [ADMIN_ROLE],
    },
    orders: {
      context: "orders",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
    newOrders: {
      context: "new_orders",
      roles: [EMPLOYEE_ROLE],
    },
    questions: {
      context: "questions",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
    promoCodes: {
      context: "promo_codes",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
  },
  canPerformAction: {
    assignOrder: {
      context: "assign_order",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
    cancelOrder: {
      context: "cancel_order",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
    updateUser: {
      context: "update_user",
      roles: [ADMIN_ROLE, SUPPORT_ROLE],
    },
  },
};

export default PERMISSIONS;
