import React from "react";
import "./index.scss";
import { FiCheckCircle } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

type Props = {
  title: string;
  type?: "primary" | "success" | "danger";
  onClick: any;
  loader?: boolean;
  disabled?: boolean;
  icon?: boolean;
};

const Button: React.FC<Props> = ({
  title,
  type = "primary",
  onClick,
  loader = false,
  disabled = false,
  icon = false,
}) => {
  return (
    <div
      className={`button_container ${type} ${disabled && "disabled"}`}
      onClick={() => {
        !disabled && onClick();
      }}
    >
      {loader ? (
        <div className="button_container__loader"></div>
      ) : icon && type === "danger" ? (
        <CgClose className="text-[14px] text-white min-[425px]:text-[24px]" />
      ) : (
        icon && (
          <FiCheckCircle className="text-[14px] text-white min-[425px]:text-[24px]" />
        )
      )}

      <button className="button_container__button">{title}</button>
    </div>
  );
};

export default Button;
