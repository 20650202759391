import React from "react";
import "./index.scss";
import { OptionValues } from "../../../../types/shared";

type Props = {
  options: OptionValues[];
  active: string;
  handleCustomOptionSelectorClick: any;
};

const CustomOptionSelector: React.FC<Props> = ({
  options,
  active,
  handleCustomOptionSelectorClick,
}) => {
  return (
    <div className="custom_options_selector">
      {options.map((item, index) => (
        <p
          key={`${item}_${index}`}
          className={`custom_options_selector__option ${
            active === item.value && "custom_options_selector__active"
          }`}
          onClick={() => handleCustomOptionSelectorClick(item.value)}
        >
          {item.title}
        </p>
      ))}
    </div>
  );
};

export default CustomOptionSelector;
