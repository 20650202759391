import React from "react";
import "./index.scss";
import NavBar from "../../NavBar";

type Props = {
  children: any;
  header: React.ReactNode;
};

const WithHeader: React.FC<Props> = ({ children, header }) => {
  return (
    <div className="layout_with_header_container">
      <NavBar />
      <div className="layout_with_header_container__content">
        <div className="layout_with_header_container__content__header">
          {header}
        </div>
        <div className="layout_with_header_container__content__body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WithHeader;
