import React from "react";
import "./index.scss";
import Modal from "..";
import Button from "../../Button";
import ErrorMessage from "../../ErrorMessage";
import TextInput from "../../Inputs/TextInput";

type Props = {
  showModal: boolean;
  setShowModal: any;
  title?: string;
  description?: string;
  password: string;
  setPassword: any;
  onSubmit: () => void;
  loading: boolean;
  error: string | null;
};

const ConfirmPassowrd: React.FC<Props> = ({
  title = "Confirm Password",
  description,
  showModal,
  setShowModal,
  password,
  setPassword,
  onSubmit,
  loading,
  error,
}) => {
  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setPassword(value);
  };

  return (
    <Modal
      header={title}
      showModal={showModal}
      setShowModal={setShowModal}
      size="small"
      underline
    >
      <div className="confirm_password_container">
        {error && <ErrorMessage message={error} />}
        <p>{description}</p>
        <TextInput
          type="password"
          placeholder="Password"
          name="password"
          onChange={handleChange}
          value={password}
        />
        <Button title="Confirm" loader={loading} onClick={onSubmit} />
      </div>
    </Modal>
  );
};

export default ConfirmPassowrd;
