import { useState } from "react";
import Button from "../../components/common/Button";
import CustomOptionSelector from "../../components/common/Inputs/CustomOptionSelector";
import Modal from "../../components/common/Modal";

type Props = {
  showModal: boolean;
  setShowModal: any;
  filterOrders: Record<string, string>;
  setFilterOrders: any;
};

const FiltersModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  filterOrders,
  setFilterOrders,
}) => {
  const [newOrderFilters, setNewOrderFilters] = useState({
    orderStatus: filterOrders.orderStatus,
    orderAssignedStatus: filterOrders.orderAssignedStatus,
  });

  const handleOrderStatusFilterChange = (value: string) => {
    setNewOrderFilters({ ...newOrderFilters, orderStatus: value });
  };
  const handleOrderAssignedFilterChange = (value: string) => {
    setNewOrderFilters({ ...newOrderFilters, orderAssignedStatus: value });
  };

  const handleApplyButtonClick = () => {
    setFilterOrders({ ...newOrderFilters });
    setShowModal(false);
  };

  return (
    <Modal
      header={"Filter Orders"}
      showModal={showModal}
      setShowModal={setShowModal}
      size="medium"
      underline
    >
      <div className="filter_modal_container">
        <div className="filter_modal_container__item">
          <p className="filter_modal_container__item__title">Order Status</p>
          <div className="filter_modal_container__item__value">
            <CustomOptionSelector
              options={[
                { title: "All", value: "all" },
                { title: "Pending", value: "pending" },
                { title: "Complete", value: "complete" },
              ]}
              active={newOrderFilters.orderStatus}
              handleCustomOptionSelectorClick={handleOrderStatusFilterChange}
            />
          </div>
        </div>

        <div className="filter_modal_container__item">
          <p className="filter_modal_container__item__title">
            Order Assigned Status
          </p>
          <div className="filter_modal_container__item__value">
            <CustomOptionSelector
              options={[
                { title: "All", value: "all" },
                { title: "Unassigned", value: "unassigned" },
                { title: "Pending", value: "pending" },
                { title: "Confirmed", value: "confirmed" },
                { title: "Declined", value: "declined" },
              ]}
              active={newOrderFilters.orderAssignedStatus}
              handleCustomOptionSelectorClick={handleOrderAssignedFilterChange}
            />
          </div>
        </div>

        <div className="filter_modal_container__actions">
          <Button
            title="Apply"
            type="primary"
            onClick={handleApplyButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FiltersModal;
