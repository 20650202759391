import { ADMIN_ROLE, EMPLOYEE_ROLE, SUPPORT_ROLE } from "../constants/roles";
import OrdersComponent from "../containers/Orders";

const OrdersRoute = {
  exact: true,
  name: "Orders",
  protected: true,
  path: "/orders",
  component: OrdersComponent,
  permissions: [EMPLOYEE_ROLE, SUPPORT_ROLE, ADMIN_ROLE],
};

export default OrdersRoute;
