import "./index.scss";

type Props = {
  message: string;
};

const ErrorMessage: React.FC<Props> = ({ message }) => {
  return <p className="error_text">{message}</p>;
};

export default ErrorMessage;
