import { useEffect, useState } from "react";
import useTheme from "../../hooks/useTheme";
import "./index.scss";
import LayoutWithHeader from "../../components/Layouts/WithHeader";
import UsersHeader from "./UsersHeader";
import UsersList from "./UsersList";
import FiltersModal from "./FiltersModal";
import UserDetailsModal from "./UserDetailsModal";
import canViewResource from "../../helpers/permissions/canViewResource";
import PERMISSIONS from "../../constants/permissions";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";
import { useNavigate } from "react-router-dom";
import OrdersRoute from "../../routes/orders";

const Users: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState("id__DESC");

  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);

  const [selectedUserID, setSelectedUserID] = useState<undefined | number>();

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [filterUsers, setFilterUsers] = useState<Record<string, string>>({
    userStatus: "all",
    userType: "all",
  });

  const {
    currentUser: { user_role },
  } = useAppSelector(selectUser);

  const [theme, readTheme] = useTheme();

  useEffect(() => {
    readTheme();
  }, [readTheme]);

  const handleUserClick = (userID: number) => {
    setSelectedUserID(userID);
    setShowUserDetailsModal(true);
  };

  useEffect(() => {
    if (user_role) {
      if (
        !canViewResource(PERMISSIONS.canViewResource.users.context, user_role)
      )
        navigate(OrdersRoute.path);
    }
  }, [navigate, user_role]);

  return (
    <LayoutWithHeader
      header={
        <UsersHeader
          setShowFilterModal={setShowFilterModal}
          sortBy={sortBy}
          setSortBy={setSortBy}
          filterUsers={filterUsers}
        />
      }
    >
      <UsersList
        theme={theme}
        handleUserClick={handleUserClick}
        filterUsers={filterUsers}
        sortBy={sortBy}
      />

      <FiltersModal
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
        filterUsers={filterUsers}
        setFilterUsers={setFilterUsers}
      />

      <UserDetailsModal
        userID={selectedUserID}
        showModal={showUserDetailsModal}
        setShowModal={setShowUserDetailsModal}
      />
    </LayoutWithHeader>
  );
};

export default Users;
