import { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layouts/WithHeader";
import OrderData from "../../../components/Orders/OrderInfo";
import Button from "../../../components/common/Button";
import ErrorMessage from "../../../components/common/ErrorMessage";
import Label from "../../../components/common/Label";
import Loader from "../../../components/common/Loader";
import ConfirmPassword from "../../../components/common/Modal/ConfirmPassword";
import getStatusColor from "../../../helpers/getStatusColor";
import parseStatus from "../../../helpers/parseStatus";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectOrder,
  resetConfirmOrDeclineOrderState,
  removeOrderFromList,
} from "../../../redux/orders/reducer";
import { IAssignedOrders, IOrder } from "../../../types/order";
import OrdersHeader from "../OrdersHeader";
import { confirmOrDeclineOrder as _confirmOrDeclineOrder } from "../../../redux/orders/actions";
import canViewResource from "../../../helpers/permissions/canViewResource";
import { selectUser } from "../../../redux/user/reducer";
import { useNavigate } from "react-router-dom";
import NewOrdersRoute from "../../../routes/assignedOrders";
import PERMISSIONS from "../../../constants/permissions";
import OrdersRoute from "../../../routes/orders";

const NewOrders = () => {
  const navigate = useNavigate();

  const {
    currentUser: { user_role },
  } = useAppSelector(selectUser);

  const {
    myAssignedOrders: { loading, error, data },
    confirmOrDeclineOrder: {
      loading: confirmOrDeclineOrderLoading,
      error: confirmOrDeclineOrderError,
      message: confirmOrDeclineOrderMessage,
    },
  } = useAppSelector(selectOrder);

  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const confirmOrDeclineData = useRef({
    id: 0,
    status: "",
    password: "",
  });

  const handleOrderClick = (orderId: number, order: IAssignedOrders) => {};

  const handleAcceptOrderClick = (orderId: number) => {
    setShowModal(true);
    confirmOrDeclineData.current.id = orderId;
    confirmOrDeclineData.current.status = "confirmed";
  };

  const handleDeclineOrderClick = (orderId: number) => {
    setShowModal(true);
    confirmOrDeclineData.current.id = orderId;
    confirmOrDeclineData.current.status = "declined";
  };

  const handleConfirmPasswordSubmit = () => {
    confirmOrDeclineData.current.password = password;

    dispatch(_confirmOrDeclineOrder(confirmOrDeclineData.current));
  };

  useEffect(() => {
    if (user_role) {
      if (
        !canViewResource(
          PERMISSIONS.canViewResource.newOrders.context,
          user_role
        )
      ) {
        navigate(OrdersRoute.path);
      }
    }
  }, [navigate, user_role]);

  if (confirmOrDeclineOrderMessage) {
    setShowModal(false);
    alert(
      `You have successfully ${confirmOrDeclineData.current.status} the order.`
    );
    dispatch(resetConfirmOrDeclineOrderState());
    dispatch(removeOrderFromList(confirmOrDeclineData.current.id));
  }

  const body = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage message={error} />;

    if (data.length === 0) {
      return <div>No Orders Yet</div>;
    }

    return data.map((item) => (
      <div
        className="flex flex-col gap-y-3 orders_container__item border-b border-[#EEEEEE] py-6 px-2 last-of-type:border-none hover:bg-[#eeeeee] cursor-pointer transition-all duration-200l ease-linear lg:py-8 lg:flex-row lg:items-end lg:w-full lg:justify-between"
        key={item.order.id}
      >
        <div
          className="flex flex-col gap-y-2 lg:gap-y-4 orders_container__item__left"
          onClick={() => handleOrderClick(item.order.id, item)}
        >
          <div className="orders_container__item__left__order_id">
            <p className="flex items-center gap-x-2 text-[#555555] text-[13px] lg:text-[15px] orders_container__item__left__order_id__title">
              Order ID{" "}
              <span className="text-[15px] orders_container__item__left__order_id__title__id">
                #{item.order.id}
              </span>
              <Label
                title={parseStatus(item.status)}
                type={getStatusColor(item.status)}
              />
            </p>
          </div>

          <OrderData order={item.order as IOrder} />
        </div>

        <div className="flex justify-between gap-x-4">
          <Button
            type="danger"
            title={"Decline Request"}
            onClick={() => handleDeclineOrderClick(item.order.id)}
            icon
          />
          <Button
            type="success"
            title={"Accept Order"}
            onClick={() => handleAcceptOrderClick(item.order.id)}
            icon
          />
        </div>
      </div>
    ));
  };

  return (
    <Layout
      header={
        <OrdersHeader
          showOrderDetails={false}
          handleBackClick={() => {}}
          order={undefined}
          handleAssignButtonClick={() => {}}
          myAssignedOrders
        />
      }
    >
      {body()}

      <ConfirmPassword
        showModal={showModal}
        setShowModal={setShowModal}
        password={password}
        description="Confirm your password to update the order"
        setPassword={setPassword}
        onSubmit={handleConfirmPasswordSubmit}
        loading={confirmOrDeclineOrderLoading}
        error={confirmOrDeclineOrderError}
      />
    </Layout>
  );
};

export default NewOrders;
