import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectOrder } from "../../redux/orders/reducer";
import { getOrderDetails } from "../../redux/orders/actions";
import Loader from "../../components/common/Loader";
import ErrorMessage from "../../components/common/ErrorMessage";
import { IOrder } from "../../types/order";
import OrderData from "../../components/Orders/OrderInfo";
import UserData from "../../components/UserData";
import Label from "../../components/common/Label";
import { selectUser } from "../../redux/user/reducer";

type Props = {
  theme: string;
  order: IOrder | undefined;
};

const OrderDetails: React.FC<Props> = ({ theme, order }) => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectUser);

  const {
    orderDetails: { loading, error, data: orderDetailsData },
  } = useAppSelector(selectOrder);

  useEffect(() => {
    dispatch(getOrderDetails(order?.id));
  }, [dispatch, order?.id]);

  const body = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage message={error} />;

    if (orderDetailsData.items.length === 0) return <div>No Order Items</div>;

    return (
      <>
        <div className="order_data_container__items overflow-x-auto">
          <p className="section_title">Order Items</p>

          <div className="order_data_container__items__header min-w-[1440px]">
            <p className="col_1">Name / Variant</p>
            <p className="col_2">Weight / Quantity</p>
            <p className="col_3">Amount</p>
            <p className="col_4">Bleach</p>
            <p className="col_5">Detergent</p>
            <p className="col_6">Softener</p>
            <p className="col_7">Comment</p>
          </div>

          <div className="order_data_container__items__content min-w-[1440px]">
            {orderDetailsData.items.map((item) => (
              <div
                key={`${item.id}`}
                className="order_data_container__items__content__row full_bleed_hover"
              >
                <p className="col_1">
                  {item.product.variant
                    ? `${item.product.name} : ${item.product.variant}`
                    : item.product.name}
                </p>
                <p className="col_2">{`${item.unit} ${item.product.unit_symbol}`}</p>
                <p className="col_3">$ {item.amount}</p>
                <p className="col_4">{item.bleach ? "Yes" : "No"}</p>
                <p className="col_5">{item.softener}</p>
                <p className="col_6">{item.detergent}</p>
                <p className="col_7">{item.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const assignedStatusColor = (status: string) => {
    switch (status) {
      case "pending":
        return "warning";
      case "confirmed":
        return "success";
      case "declined":
        return "danger";
      default:
        return "warning";
    }
  };

  return (
    <div className={`px-2 order_data_container ${theme}`}>
      {order && (
        <div className="order_data_container__details">
          <p className="section_title">Order Details</p>

          <OrderData order={order} />
        </div>
      )}

      <div className="order_data_container__user_related xl:!flex-row justify-between">
        {order && orderDetailsData.orderedBy && (
          <div className="order_data_container__user_related__ordered_by">
            <p className="section_title">Ordered By</p>

            <UserData user={orderDetailsData.orderedBy} />
          </div>
        )}

        {order &&
          ["admin", "support"].includes(currentUser.user_role) &&
          orderDetailsData.assignedTo && (
            <div className="order_data_container__user_related__assigned_to">
              <p className="section_title">
                Assigned To
                <Label
                  title={order.assigned_status[0].status}
                  type={assignedStatusColor(order.assigned_status[0].status)}
                />
              </p>

              <UserData user={orderDetailsData.assignedTo} />
            </div>
          )}
      </div>

      {body()}
    </div>
  );
};

export default OrderDetails;
