const getStatusColor = (status: string) => {
  switch (status) {
    case "complete":
      return "success";
    case "pending_payment":
    case "canceled":
      return "danger";
    default:
      return "warning";
  }
};

export default getStatusColor;
