import { useContext } from "react";
import { ThemeContext } from "../components/ThemeContextProvider";

const useTheme = (): any => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const readTheme = async () => {
    const data = await localStorage.getItem("theme");
    if (data !== null && data !== undefined && data !== theme) {
      toggleTheme();
    }
  };

  const updateTheme = async () => {
    await localStorage.setItem("theme", theme === "light" ? "dark" : "light");
    toggleTheme();
  };

  return [theme, readTheme, updateTheme];
};

export default useTheme;
