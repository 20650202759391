import { ADMIN_ROLE } from "../constants/roles";
import OverviewComponent from "../containers/Overview";

const OverviewRoute = {
  exact: true,
  name: "Overview",
  protected: true,
  path: "/",
  component: OverviewComponent,
  permissions: [ADMIN_ROLE],
};

export default OverviewRoute;
