import Label from "../../components/common/Label";
import UserData from "../../components/UserData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers } from "../../redux/user/actions";
import { selectUser } from "../../redux/user/reducer";
import Loader from "../../components/common/Loader";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useEffect } from "react";
import { formatDate } from "../../helpers/parseDateAndTime";

type Props = {
  theme: string;
  handleUserClick: any;
  filterUsers: Record<string, string>;
  sortBy: string;
};

const UsersList: React.FC<Props> = ({
  theme,
  handleUserClick,
  filterUsers,
  sortBy,
}) => {
  const {
    users: { loading, error, data },
  } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUsers({ filterUsers, sortBy }));
  }, [filterUsers, sortBy]); // eslint-disable-line

  const body = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage message={error} />;

    if (data.length === 0) {
      return <div>No Users Found</div>;
    }
    return data.map((user) => (
      <div
        className="users_container__item"
        onClick={() => handleUserClick(user.id)}
        key={user.id}
      >
        <div className="users_container__item__left">
          <UserData user={user} />
        </div>
        <div className="users_container__item__right">
          <div className="user_account_status">
            <Label title="active" type="success" />
          </div>
          <div className="user_join_date">
            Joined Date
            <span className="user_join_date__value">
              {formatDate(user.created_at)}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  return <div className={`users_container ${theme}`}>{body()}</div>;
};

export default UsersList;
