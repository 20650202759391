import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../helpers/axios";
import setAuthData from "../../helpers/localStorage/setAuthData";

export const login = createAsyncThunk<any, any>(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await server.post("/auth/login", data);

      if (response.status === 200) {
        setAuthData(true);

        return response.data;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await server.get("/user");

      if (response.status === 200) return response.data;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ status, message }: any) {
      if (status === 401) {
        setAuthData(false);
        // @ts-ignore
        server.defaults.headers.Authorization = "";
      }

      return rejectWithValue(message);
    }
  }
);

export const searchUsers = createAsyncThunk<any, any>(
  "user/searchUsers",
  async (
    {
      name,
      phone_number,
      email,
      sortBy,
      filterUsers: { userType, userStatus },
    }: {
      name: string;
      phone_number: string;
      email: string;
      sortBy: string;
      filterUsers: { userType: string; userStatus: string };
    },
    { rejectWithValue }
  ) => {
    try {
      let parsedQuery = `sortBy=${sortBy}&user_role=${userType}&status=${userStatus}`;

      if (name) parsedQuery += `&name=${name}`;
      else if (phone_number) parsedQuery += `&phone_number=${phone_number}`;
      else if (email) parsedQuery += `&email=${email}`;

      const response = await server.get(`/users/search?${parsedQuery}`);

      if (response.status === 200) return response;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ status, message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const getUsers = createAsyncThunk<any, any>(
  "user/getUsers",
  async (
    {
      sortBy,
      filterUsers: { userType, userStatus },
    }: {
      sortBy: string;
      filterUsers: { userType: string; userStatus: string };
    },
    { rejectWithValue }
  ) => {
    const parsedQuery = `sortBy=${sortBy}&user_role=${userType}&status=${userStatus}`;

    try {
      const response = await server.get(`/users?${parsedQuery}`);
      if (response.status === 200) return response;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      rejectWithValue(message);
    }
  }
);

export const searchEmployees = createAsyncThunk<any, any>(
  "user/searchEmployees",
  async (
    {
      name,
      phone_number,
      email,
      assign,
    }: {
      name: string;
      phone_number: string;
      email: string;
      assign: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      let parsedQuery = ``;

      if (name) parsedQuery = `name=${name}`;
      else if (phone_number) parsedQuery = `phone_number=${phone_number}`;
      else if (email) parsedQuery = `email=${email}`;

      if (assign) parsedQuery += "&user_role=admin,employee,support";

      const response = await server.get(`/users/search?${parsedQuery}`);

      if (response.status === 200) return response;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ status, message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await server.post("/auth/logout");

      if (response.status === 200) {
        setAuthData(false);
        // @ts-ignore
        server.defaults.headers.Authorization = "";

        return response.data;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);
