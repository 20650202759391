import { useState } from "react";
import Button from "../../components/common/Button";
import CustomOptionSelector from "../../components/common/Inputs/CustomOptionSelector";
import Modal from "../../components/common/Modal";

type Props = {
  showModal: boolean;
  setShowModal: any;
  filterUsers: Record<string, string>;
  setFilterUsers: any;
};

const FiltersModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  filterUsers,
  setFilterUsers,
}) => {
  const [newOrderFilters, setNewOrderFilters] = useState({
    userStatus: filterUsers.userStatus,
    userType: filterUsers.userType,
  });

  const handleUserStatusFilterChange = (value: string) => {
    setNewOrderFilters({ ...newOrderFilters, userStatus: value });
  };
  const handleUserTypeFilterChange = (value: string) => {
    setNewOrderFilters({ ...newOrderFilters, userType: value });
  };

  const handleApplyButtonClick = () => {
    setFilterUsers({ ...newOrderFilters });
    setShowModal(false);
  };

  return (
    <Modal
      header={"Filter Users"}
      showModal={showModal}
      setShowModal={setShowModal}
      size="medium"
      underline
    >
      <div className="filter_modal_container">
        <div className="filter_modal_container__item">
          <p className="filter_modal_container__item__title">User Type</p>
          <div className="filter_modal_container__item__value">
            <CustomOptionSelector
              options={[
                { title: "All", value: "all" },
                { title: "Admin", value: "admin" },
                { title: "Employee", value: "employee" },
                { title: "Support", value: "support" },
                { title: "User", value: "user" },
              ]}
              active={newOrderFilters.userType}
              handleCustomOptionSelectorClick={handleUserTypeFilterChange}
            />
          </div>
        </div>

        <div className="filter_modal_container__item">
          <p className="filter_modal_container__item__title">Status</p>
          <div className="filter_modal_container__item__value">
            <CustomOptionSelector
              options={[
                { title: "All", value: "all" },
                { title: "Active", value: "active" },
                { title: "Suspended", value: "suspended" },
                { title: "Blocked", value: "blocked" },
              ]}
              active={newOrderFilters.userStatus}
              handleCustomOptionSelectorClick={handleUserStatusFilterChange}
            />
          </div>
        </div>

        <div className="filter_modal_container__actions">
          <Button
            title="Apply"
            type="primary"
            onClick={handleApplyButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FiltersModal;
