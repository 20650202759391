import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { setLocalStorage } from "../../helpers/localStorageHelper";
import Loader from "../common/Loader";

type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
  loading: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = "/login",
  children,
  loading,
}): any => {
  const {
    REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL = "HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL",
  } = process.env;

  const { pathname } = useLocation();

  useEffect(() => {
    setLocalStorage(REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL, pathname);
  }, [REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL, pathname]);

  if (loading) return <Loader />;

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
