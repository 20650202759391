import Login from "./login";
import Overview from "./overview";
import Orders from "./orders";
import OrderDetails from "./orderDetails";
import Users from "./users";
import NewOrders from "./assignedOrders";
import Questions from "./questions";
import PromoCodes from "./promoCodes";

const routes = [
  Login,
  Overview,
  Orders,
  OrderDetails,
  NewOrders,
  Users,
  Questions,
  PromoCodes,
];

export default routes;
