import React, { useEffect, useRef } from "react";
import "./index.scss";
import LeftArrowIcon from "../../assets/icons/triangle_left_gray.png";
import RightArrowIcon from "../../assets/icons/triangle_right_gray.png";
import DownArrowIcon from "../../assets/icons/triangle_down_gray.png";
import { OptionValues } from "../../types/shared";

type Props = {
  selectedLimit: string;
  limitValues: OptionValues[];
  onLimitChange: any;
  selectedPage: number;
  pageValues: number[];
  updatePageValues: any;
  onPageChange: any;
  openDropdown: boolean;
  setOpenDropdown: any;
  maxPage: number;
};

const Pagination: React.FC<Props> = ({
  selectedLimit,
  limitValues,
  onLimitChange,
  selectedPage,
  pageValues,
  updatePageValues,
  onPageChange,
  openDropdown,
  setOpenDropdown,
  maxPage,
}) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOpenDropdown]);

  const showNextPages = () => {
    let newPage = pageValues[pageValues.length - 1] + 1;

    if (newPage > maxPage) {
      newPage = maxPage;
    } else updatePageValues([...pageValues.slice(1), newPage]);

    onPageChange(newPage);
  };

  const showPrevPages = () => {
    let newPage = pageValues[0] - 1;

    if (newPage <= 0) {
      newPage = 1;
    } else {
      updatePageValues([
        newPage,
        ...pageValues.slice(0, pageValues.length - 1),
      ]);
    }

    onPageChange(newPage);
  };

  return (
    <div className="pagination_container card">
      <div
        className="pages_limit_dropdown_container"
        onClick={() => setOpenDropdown(!openDropdown)}
        // @ts-ignore
        ref={ref}
      >
        <p className="pages_limit_dropdown_container__title">{selectedLimit}</p>
        <img src={DownArrowIcon} alt="Drop Down Icon" />

        <div
          className={`pages_limit_dropdown_container__values card ${
            openDropdown && "open_dropdown"
          }`}
        >
          {limitValues.map((item, index) => (
            <p
              key={`${item}_${index}`}
              className={`pages_limit_dropdown_container__values__value ${
                selectedLimit === item.title && "active"
              }`}
              onClick={() => onLimitChange(item)}
            >
              {item.title}
            </p>
          ))}
        </div>
      </div>

      <div className="pagination_container__pages_number_container">
        <img
          src={LeftArrowIcon}
          alt="Left arrow icon"
          onClick={showPrevPages}
        />
        <div className="pagination_container__pages_number_container__pages">
          {pageValues.map((item, index) => (
            <div
              key={`pages_${item}_${index}`}
              className={`pagination_container__pages_number_container__pages__page ${
                selectedPage === item &&
                "pagination_container__pages_number_container__pages__active"
              }`}
              onClick={() => {
                if (item > maxPage) return;
                onPageChange(item);
              }}
            >
              {item}
            </div>
          ))}
        </div>
        <img
          src={RightArrowIcon}
          alt="Right arrow icon"
          onClick={showNextPages}
        />
      </div>
    </div>
  );
};

export default Pagination;
