import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../helpers/axios";

export const getOrders = createAsyncThunk<any, any>(
  "order/getOrders",
  async (
    {
      sortBy,
      filterOrders: { orderStatus, orderAssignedStatus },
    }: {
      sortBy: string;
      filterOrders: { orderStatus: string; orderAssignedStatus: string };
    },
    { rejectWithValue }
  ) => {
    let parsedQuery = `sortBy=${sortBy}&order_status=${orderStatus}&order_assigned_status=${orderAssignedStatus}`;

    try {
      const response = await server.get(`/orders/all?${parsedQuery}`);

      if (response.status === 200) {
        return response;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const getMyAssignedOrders = createAsyncThunk<any, any>(
  "order/getMyAssignedOrders",
  async (
    {
      sortBy,
    }: {
      sortBy: string;
    },
    { rejectWithValue }
  ) => {
    let parsedQuery = `sortBy=${sortBy}`;

    try {
      const response = await server.get(`/orders/assigned?${parsedQuery}`);

      if (response.status === 200) {
        return response;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const getOrderDetails = createAsyncThunk<any, any>(
  "order/getOrderDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await server.get(`/orders/${data}`);

      if (response.status === 200) {
        return response.data;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const confirmOrDeclineOrder = createAsyncThunk<any, any>(
  "order/confirmOrDeclineOrder",
  async ({ id, status, password }, { rejectWithValue }) => {
    try {
      const response = await server.post(`/orders/${id}/confirm_or_decline`, {
        status,
        password,
      });

      if (response.status === 200) {
        // @ts-ignore
        return response.message;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const createOrderInvoice = createAsyncThunk<any, any>(
  "order/createOrderInvoice",
  async (orderID, { rejectWithValue }) => {
    try {
      const response = await server.post(
        `/orders/${orderID}/create_checkout_session`
      );

      if (response.status === 200) {
        return response.data;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrderStatus = createAsyncThunk<any, any>(
  "order/updateOrderStatus",
  async ({ id, status, password, items }, { rejectWithValue }) => {
    try {
      const body: Record<string, any> = { status, password };

      if (items && items.length > 0) body.items = items;

      const response = await server.put(`/orders/${id}`, body);

      if (response.status === 200) {
        return response;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const assignOrder = createAsyncThunk<any, any>(
  "order/assignOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await server.post(`/orders/${data.orderID}`, data.body);

      if (response.status === 200) {
        // @ts-ignore
        return response.message;
      }

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ message }: any) {
      return rejectWithValue(message);
    }
  }
);

export const searchOrders = createAsyncThunk<any, any>(
  "order/searchOrders",
  async (
    {
      order_id,
      user_id,
      sortBy,
      filterOrders: { orderStatus, orderAssignedStatus },
    }: {
      order_id: number;
      user_id: number;
      sortBy: string;
      filterOrders: { orderStatus: string; orderAssignedStatus: string };
    },
    { rejectWithValue }
  ) => {
    try {
      let parsedQuery = `sortBy=${sortBy}&order_status=${orderStatus}&order_assigned_status=${orderAssignedStatus}`;

      if (order_id) parsedQuery += `&order_id=${order_id}`;
      else if (user_id) parsedQuery += `&user_id=${user_id}`;

      const response = await server.get(`/orders/search?${parsedQuery}`);

      if (response.status === 200) return response;

      // @ts-ignore
      throw new Error(response.message);
    } catch ({ status, message }: any) {
      return rejectWithValue(message);
    }
  }
);
