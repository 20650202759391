import { EMPLOYEE_ROLE } from "../constants/roles";
import AssignedOrdersComponent from "../containers/Orders/AssignedOrders";

const AssignedOrdersRoute = {
  exact: true,
  name: "Assigned Orders",
  protected: true,
  path: "/assigned_orders",
  component: AssignedOrdersComponent,
  permissions: [EMPLOYEE_ROLE],
};

export default AssignedOrdersRoute;
