import { SUPPORT_ROLE, ADMIN_ROLE } from "../constants/roles";
import UsersComponent from "../containers/Users";

const UsersRoute = {
  exact: true,
  name: "Users",
  protected: true,
  path: "/users",
  component: UsersComponent,
  permissions: [SUPPORT_ROLE, ADMIN_ROLE],
};

export default UsersRoute;
