const parseStatus = (status: string) => {
  const data = status.split("_");
  return data
    .map((item) => {
      const firstLetter = item[0].toUpperCase();
      item = `${firstLetter}${item.slice(1)}`;
      return item;
    })
    .join(" ");
};

export default parseStatus;
