import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getPromoCodes } from "./actions";
import { IUser } from "../../types/user";

export type PromoCodeState = {
  promoCodes: {
    loading: boolean;
    error: string | null;
    data: IUser[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
};

const initialState: PromoCodeState = {
  promoCodes: {
    loading: false,
    error: null,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
};

const promoCodeSlice = createSlice({
  name: "promo_code",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Users
    builder
      .addCase(getPromoCodes.pending, (state) => {
        state.promoCodes.loading = true;
        state.promoCodes.error = null;
      })
      .addCase(getPromoCodes.fulfilled, (state, { payload }) => {
        state.promoCodes.loading = false;
        state.promoCodes.error = null;
        state.promoCodes.data = payload.data;
        state.promoCodes.meta = payload.meta;
      })
      .addCase(getPromoCodes.rejected, (state, { payload }) => {
        state.promoCodes.loading = false;
        state.promoCodes.error = payload as string;
        state.promoCodes.data = initialState.promoCodes.data;
        state.promoCodes.meta = initialState.promoCodes.meta;
      });
  },
});

export const selectPromoCode = (state: RootState) => state.promo_code;

// export const {  } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
