import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import SortIcon from "../../assets/icons/sort_gray.png";

import DropdownIcon from "../../components/common/Inputs/DropdownIcon";
import Pagination from "../../components/Pagination";
import { OptionValues } from "../../types/shared";
import IconButton from "../../components/common/Inputs/IconButton";
import AssignButton from "./AssignButton";
import { IOrder, IOrderDetailsItems } from "../../types/order";
import SearchWithFilter from "../../components/common/Inputs/SearchWithFilter";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getOrders,
  searchOrders,
  getMyAssignedOrders,
  updateOrderStatus,
  createOrderInvoice,
} from "../../redux/orders/actions";
import { selectUser } from "../../redux/user/reducer";
import Button from "../../components/common/Button";
import FiltersModal from "./FiltersModal";
import ConfirmPassword from "../../components/common/Modal/ConfirmPassword";
import {
  selectOrder,
  resetUpdateOrderState,
  updateOrderItem,
} from "../../redux/orders/reducer";
import Modal from "../../components/common/Modal";
import ErrorMessage from "../../components/common/ErrorMessage";
import TextInput from "../../components/common/Inputs/TextInput";
import {
  HiOutlineFilter,
  // HiOutlineSortAscending
} from "react-icons/hi";

type Props = {
  showOrderDetails: boolean;
  order: IOrder | undefined;
  setSelectedOrder?: any;
  handleBackClick: any;
  handleAssignButtonClick: any;
  myAssignedOrders?: boolean;
};
const OrdersHeader: React.FC<Props> = ({
  showOrderDetails,
  order,
  setSelectedOrder,
  handleBackClick,
  handleAssignButtonClick,
  myAssignedOrders = false,
}) => {
  const didMount = useRef(false);
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectUser);
  const {
    createOrderInvoice: {
      loading: invoiceLoading,
      error: invoiceError,
      data: invoiceData,
    },
    updateOrderStatus: { loading, error, message, data },
    orders: { data: allOrders },
    orderDetails: {
      data: { items: orderItems },
    },
  } = useAppSelector(selectOrder);

  // Sort related state
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [sortBy, setSortBy] = useState("id__DESC");

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [filterOrders, setFilterOrders] = useState<Record<string, string>>({
    orderStatus: "all",
    orderAssignedStatus: "all",
  });

  const handleSortInput = (value: string) => {
    setSortBy(value);
  };

  // Pagination Related state
  const [paginationPages, setPaginationPages] = useState([1, 2, 3, 4]);
  const [selectedPageLimit, setSelectedPageLimit] = useState({
    title: "50 / Page",
    value: "50",
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const [openPaginationDropdown, setOpenPaginationDropdown] = useState(false);

  const handlePageLimitChange = (value: OptionValues) => {
    setSelectedPageLimit(value);
  };

  const handlePageChange = (value: number) => {
    setSelectedPage(value);
  };

  // Search Order related
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchColumn, setSearchColumn] = useState<OptionValues>({
    title: "Order ID",
    value: "order_id",
  });

  const handleSearchFilterInput = (value: OptionValues) => {
    setSearchColumn(value);
  };

  const handleSearchInput = async ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchValue(value);
  };

  useEffect(() => {
    let findOrders: any;
    if (didMount.current) {
      findOrders = setTimeout(() => {
        if (searchValue === "") dispatch(getOrders({ sortBy, filterOrders }));

        const queryData: Record<string, string> = {};
        queryData[searchColumn.value] = searchValue;
        dispatch(searchOrders({ ...queryData, sortBy, filterOrders }));
      }, 1000);
    } else didMount.current = true;
    return () => clearTimeout(findOrders);
  }, [searchValue]); // eslint-disable-line

  useEffect(() => {
    if (myAssignedOrders) {
      dispatch(getMyAssignedOrders({ sortBy }));
    } else dispatch(getOrders({ filterOrders, sortBy }));
  }, [dispatch, filterOrders, sortBy, myAssignedOrders]);

  // Update Order Related
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] =
    useState(false);
  const [showLaundryDoneModal, setShowLaundryDoneModal] = useState(false);
  const [password, setPassword] = useState("");
  const updateOrderData = useRef<Record<string, any>>({
    id: -1,
    status: "",
    password: "",
  });

  const handleOrderUpdate = () => {
    switch (order?.status) {
      case "pending_pickup":
        setShowConfirmPasswordModal(true);
        updateOrderData.current.status = "pending_wash";
        break;
      case "pending_wash":
        setShowLaundryDoneModal(true);
        updateOrderData.current.status = "pending_payment";
        break;
      case "pending_delivery":
        setShowConfirmPasswordModal(true);
        updateOrderData.current.status = "complete";
        break;
    }
  };

  const handleOrderCancel = () => {
    // Cancel The Order
    setShowConfirmPasswordModal(true);
    updateOrderData.current.status = "canceled";
  };

  const handleOrderInvoiceGeneration = () => {
    if (order?.status === "pending_payment") {
      if (order?.payment_url) {
        navigator.clipboard.writeText(order.payment_url);
      } else dispatch(createOrderInvoice(order.id));
    }
  };

  const handleOrderUpdateText = () => {
    switch (order?.status) {
      case "pending_pickup":
        return "Confirm Pickup";
      case "pending_wash":
        return "Laundry Done";
      case "pending_delivery":
        return "Confirm Delivery";
      default:
        return "Error";
    }
  };

  const handleConfirmPasswordSubmit = () => {
    if (order) {
      updateOrderData.current.id = order.id;
      updateOrderData.current.password = password;

      if (showLaundryDoneModal) {
        updateOrderData.current.items = orderItems.map((item) => {
          return {
            product_id: Number(item.product_id),
            unit: Number(item.unit),
          };
        });
      }
      dispatch(updateOrderStatus(updateOrderData.current));
    }
  };

  useEffect(() => {
    if (message) {
      alert(`Order has been updated successfully`);
      if (showConfirmPasswordModal) setShowConfirmPasswordModal(false);
      if (showLaundryDoneModal) setShowLaundryDoneModal(false);

      const updatedOrder = allOrders.find((item) => item.id === data.id);
      if (updatedOrder)
        setSelectedOrder({
          ...updatedOrder,
          status: data.status,
          amount: data.amount ? data.amount : updatedOrder.amount,
        });

      dispatch(resetUpdateOrderState());
    }
  }, [
    allOrders,
    data,
    dispatch,
    message,
    setSelectedOrder,
    showConfirmPasswordModal,
    showLaundryDoneModal,
  ]);

  return (
    <div className="relative orders_page_header_container w-full">
      <Header
        title="Orders"
        order={order}
        handleBackClick={handleBackClick}
        showOrderDetails={showOrderDetails}
        menuActions={
          showOrderDetails ? (
            ["employee"].includes(currentUser.user_role) &&
            order &&
            ["pending_pickup", "pending_wash", "pending_delivery"].includes(
              order.status
            ) && (
              <Button
                type="success"
                title={handleOrderUpdateText()}
                onClick={handleOrderUpdate}
                icon
              />
            )
          ) : (
            <>
              <IconButton onClick={() => setShowFilterModal(true)}>
                <HiOutlineFilter className="text-[36px] text-[#AAAAAA]" />
              </IconButton>

              <DropdownIcon
                icon={SortIcon}
                className="sort_by_dropdown"
                values={[
                  {
                    title: "Pick up Ascending",
                    value: "pickup_year_month_day__ASC",
                  },
                  {
                    title: "Pick up Descending",
                    value: "pickup_year_month_day__DESC",
                  },
                  {
                    title: "Delivery Ascending",
                    value: "delivery_year_month_day__ASC",
                  },
                  {
                    title: "Delivery Descending",
                    value: "delivery_year_month_day__DESC",
                  },
                  { title: "Created At Ascending", value: "id__ASC" },
                  { title: "Created At Descending", value: "id__DESC" },
                ]}
                selected={sortBy}
                onChange={handleSortInput}
                openDropdown={openSortDropdown}
                setOpenDropdown={setOpenSortDropdown}
              />
            </>
          )
        }
      >
        {showOrderDetails ? (
          ["admin", "support"].includes(currentUser.user_role) &&
          order?.status === "pending" ? (
            <>
              <AssignButton
                handleAssignButtonClick={handleAssignButtonClick}
                orderAssignStatus={order?.assigned_status[0].status}
                orderID={order?.id}
              />

              <Button
                title="Cancel Order"
                type="danger"
                onClick={handleOrderCancel}
                loader={loading}
                disabled={loading}
              />
            </>
          ) : (
            order?.status !== "canceled" && (
              <div className="flex flex-col gap-2 md:flex-row">
                <Button
                  title={
                    order?.payment_url
                      ? "Copy Payment Link"
                      : `Generate Invoice`
                  }
                  onClick={handleOrderInvoiceGeneration}
                  loader={invoiceLoading}
                  disabled={invoiceLoading}
                />
                <Button
                  title="Cancel Order"
                  type="danger"
                  onClick={handleOrderCancel}
                  loader={loading}
                  disabled={loading}
                />
              </div>
            )
          )
        ) : (
          <>
            <div className="hidden gap-x-4 lg:flex">
              <IconButton onClick={() => setShowFilterModal(true)}>
                <HiOutlineFilter className="text-[36px] text-[#AAAAAA]" />
              </IconButton>
              <DropdownIcon
                icon={SortIcon}
                className="sort_by_dropdown"
                values={[
                  {
                    title: "Pick up Ascending",
                    value: "pickup_year_month_day__ASC",
                  },
                  {
                    title: "Pick up Descending",
                    value: "pickup_year_month_day__DESC",
                  },
                  {
                    title: "Delivery Ascending",
                    value: "delivery_year_month_day__ASC",
                  },
                  {
                    title: "Delivery Descending",
                    value: "delivery_year_month_day__DESC",
                  },
                  { title: "Created At Ascending", value: "id__ASC" },
                  { title: "Created At Descending", value: "id__DESC" },
                ]}
                selected={sortBy}
                onChange={handleSortInput}
                openDropdown={openSortDropdown}
                setOpenDropdown={setOpenSortDropdown}
              />
            </div>

            <div className="flex flex-col gap-y-4 md:flex-row md:justify-between md:items-center gap-x-3">
              {myAssignedOrders ? null : (
                <SearchWithFilter
                  name="search_order"
                  placeholder="Search Order"
                  value={searchValue}
                  onSearchChange={handleSearchInput}
                  values={[
                    { title: "Order ID", value: "order_id" },
                    { title: "User ID", value: "user_id" },
                  ]}
                  selected={searchColumn.title}
                  onFilterChange={handleSearchFilterInput}
                  openDropdown={openSearchDropdown}
                  setOpenDropdown={setOpenSearchDropdown}
                />
              )}

              <Pagination
                selectedLimit={selectedPageLimit.title}
                limitValues={[
                  { title: "50 / Page", value: "50" },
                  { title: "150 / Page", value: "150" },
                  { title: "250 / Page", value: "250" },
                  { title: "350 / Page", value: "350" },
                  { title: "500 / Page", value: "500" },
                ]}
                onLimitChange={handlePageLimitChange}
                selectedPage={selectedPage}
                pageValues={paginationPages}
                onPageChange={handlePageChange}
                updatePageValues={setPaginationPages}
                openDropdown={openPaginationDropdown}
                setOpenDropdown={setOpenPaginationDropdown}
                maxPage={0}
              />
            </div>
          </>
        )}
      </Header>

      <FiltersModal
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
        filterOrders={filterOrders}
        setFilterOrders={setFilterOrders}
      />

      <ConfirmPassword
        showModal={showConfirmPasswordModal}
        setShowModal={setShowConfirmPasswordModal}
        password={password}
        description="Confirm your password to update the order"
        setPassword={setPassword}
        onSubmit={handleConfirmPasswordSubmit}
        loading={loading}
        error={error}
      />

      <Modal
        header={`Update Order ${order?.id}`}
        showModal={showLaundryDoneModal}
        setShowModal={setShowLaundryDoneModal}
        underline
      >
        <div className="flex flex-col items-start">
          {error && <ErrorMessage message={error} />}
          <p className="section_title">Items</p>
          <div className="flex flex-col items-start gap-y-3 flex-1 w-full">
            {orderItems.map((item) => (
              <OrderItem key={`${item.id}_modal`} item={item} />
            ))}
          </div>

          <TextInput
            type="password"
            placeholder="Password"
            name="password"
            onChange={({
              target: { value },
            }: {
              target: { value: string };
            }) => {
              setPassword(value);
            }}
            value={password}
            className="!mt-0"
            inputClassName="!pl-2"
          />

          <div className="flex justify-end gap-x-2 w-full my-4">
            <Button title="Reset" type="danger" onClick={() => {}} />
            <Button title="Submit" onClick={handleConfirmPasswordSubmit} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const OrderItem = ({ item }: { item: IOrderDetailsItems }) => {
  const dispatch = useAppDispatch();
  const [newValue, setNewValue] = useState(item.unit);

  const handleChange = (
    { target: { value } }: { target: { value: number } },
    orderID: number
  ) => {
    setNewValue(value);
    dispatch(updateOrderItem({ id: orderID, unit: value }));
  };

  return (
    <div className="w-full">
      <p className="text-[14px]">{item.product.name}</p>
      <div className="flex w-full items-center justify-between">
        {item.product.unit_symbol === "lb" ? (
          <>
            <p className="text-[12px] text-[#555555]">
              Weight <span className="font-bold text-black">(lb)</span>
            </p>
            <TextInput
              type="number"
              name="order_item"
              onChange={(e: any) => {
                handleChange(e, item.id);
              }}
              value={newValue}
              className="!w-[80px] !mt-0"
              inputClassName="!text-right !py-0 !px-2 !text-[14px]"
            />
          </>
        ) : (
          <>
            <p className="text-[12px] text-[#555555]">
              Quantity <span className="font-bold text-black">(pcs)</span>
            </p>
            <p className="text-[14px] px-2">{item.unit}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersHeader;
