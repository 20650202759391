import { ADMIN_ROLE, SUPPORT_ROLE } from "../constants/roles";
import QuestionsComponent from "../containers/Questions";

const QuestionRoute = {
  exact: true,
  name: "Questions",
  protected: true,
  path: "/questions",
  component: QuestionsComponent,
  permissions: [ADMIN_ROLE, SUPPORT_ROLE],
};

export default QuestionRoute;
