import { useEffect, useState } from "react";
import useTheme from "../../hooks/useTheme";
import "./index.scss";
import LayoutWithHeader from "../../components/Layouts/WithHeader";
import OrdersHeader from "./OrdersHeader";
import OrderDetails from "./OrderDetails";
import OrdersList from "./OrdersList";
import AssignModal from "./AssignModal";
import { IOrder } from "../../types/order";

const Orders: React.FC<{}> = () => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const [selectedOrderToAssign, setSelectedOrderToAssign] = useState<
    undefined | number
  >();

  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>();

  const [showAssignModal, setShowAssignModal] = useState(false);

  const [theme, readTheme] = useTheme();
  useEffect(() => {
    readTheme();
  }, [readTheme]);

  const handleOrderClick = (orderID: number, order: IOrder) => {
    setShowOrderDetails(true);
    setSelectedOrder(order);
  };

  const handleBackClick = () => {
    setShowOrderDetails(false);
    setSelectedOrder(undefined);
  };

  const handleAssignButtonClick = (orderID: number) => {
    setSelectedOrderToAssign(orderID);
    setShowAssignModal(true);
  };

  return (
    <LayoutWithHeader
      header={
        <OrdersHeader
          showOrderDetails={showOrderDetails}
          handleBackClick={handleBackClick}
          order={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          handleAssignButtonClick={handleAssignButtonClick}
        />
      }
    >
      {showOrderDetails && selectedOrder ? (
        <OrderDetails theme={theme} order={selectedOrder} />
      ) : (
        <OrdersList
          handleAssignButtonClick={handleAssignButtonClick}
          handleOrderClick={handleOrderClick}
          theme={theme}
        />
      )}

      <AssignModal
        showModal={showAssignModal}
        setShowModal={setShowAssignModal}
        orderID={selectedOrderToAssign}
      />
    </LayoutWithHeader>
  );
};

export default Orders;
