import { useCallback, useEffect, useRef, useState } from "react";
import "./index.scss";
import useTheme from "../../hooks/useTheme";
import TextInput from "../../components/common/Inputs/TextInput";
import Button from "../../components/common/Button";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";
import { login } from "../../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../helpers/localStorageHelper";

const Login: React.FC<{}> = () => {
  const {
    REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL = "HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL",
  } = process.env;

  const [theme, readTheme] = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    readTheme();
  }, [readTheme]);

  const dispatch = useAppDispatch();

  const {
    login: { loading, error },
    isAuthenticated,
  } = useAppSelector(selectUser);

  const authenticate = async () => {
    dispatch(login(userData));
  };

  const debounceRef = useRef<any>(null);
  const activeInput = useRef<string>("email");

  const [userData, setUserData] = useState<Record<string, string>>({
    email: "",
    password: "",
  });

  const [validFields, setValidFields] = useState({
    email: {
      isValid: false,
      errorInfo: "",
    },
    password: {
      isValid: false,
      errorInfo: "",
    },
  });

  const validateFields = useCallback(
    (field: any) => {
      if (userData[field].length === 0) {
        setValidFields({
          ...validFields,
          [field]: {
            isValid: false,
            errorInfo: ``,
          },
        });
        return;
      }

      if (field === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValid = emailRegex.test(userData[field]);

        if (!isValid) {
          setValidFields({
            ...validFields,
            [field]: {
              isValid: false,
              errorInfo: "Email has an invalid format",
            },
          });
          return;
        }
      }

      if (field === "password" && userData[field].length <= 4) {
        setValidFields({
          ...validFields,
          [field]: {
            isValid: false,
            errorInfo: "Password must be atleast 5 characters",
          },
        });
        return;
      }

      setValidFields({
        ...validFields,
        [field]: { isValid: true, errorInfo: "" },
      });
    },
    [userData, validFields]
  );

  useEffect(() => {
    debounceRef.current = setTimeout(() => {
      validateFields(activeInput.current);
    }, 500);

    return () => clearTimeout(debounceRef.current);
  }, [validateFields]);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    activeInput.current = name;

    setUserData({ ...userData, [name]: value });

    setValidFields({
      ...validFields,
      [name]: {
        isValid: false,
        errorInfo: "",
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      const previousUrl = getLocalStorage(
        REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL
      ) as unknown as string;

      navigate(previousUrl || "/");
    }
  }, [
    REACT_APP_HASTE_LAUNDRY_DASHBOARD_PREVIOUS_URL,
    isAuthenticated,
    navigate,
  ]);

  return (
    <div className={`login_container ${theme}`}>
      <p className="login_container__title text-center">
        Haste Laundry Dashboard
      </p>
      <div className="login_container__form ">
        <p className="login_container__form__title">Welcome!</p>
        {error && <ErrorMessage message={error} />}
        <div className="login_container__form__container">
          <TextInput
            placeholder="Email"
            name="email"
            onChange={handleChange}
            value={userData.email}
            error={!validFields.email.isValid}
            errorMessage={validFields.email.errorInfo}
          />
          <TextInput
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={userData.password}
            error={!validFields.password.isValid}
            errorMessage={validFields.password.errorInfo}
          />
        </div>
        <Button
          title="Login"
          loader={loading}
          onClick={authenticate}
          disabled={
            // !validFields.email.isValid ||
            // !validFields.password.isValid ||
            loading
          }
        />
      </div>
    </div>
  );
};

export default Login;
