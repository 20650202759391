import { useEffect } from "react";
import useTheme from "../../hooks/useTheme";
import Layout from "../../components/Layouts/Default";
import PERMISSIONS from "../../constants/permissions";
import canViewResource from "../../helpers/permissions/canViewResource";
import { useNavigate } from "react-router-dom";
import OrdersRoute from "../../routes/orders";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/reducer";

const Questions: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [theme, readTheme] = useTheme();

  const {
    currentUser: { user_role },
  } = useAppSelector(selectUser);

  useEffect(() => {
    readTheme();
  }, [readTheme]);

  useEffect(() => {
    if (user_role) {
      if (
        !canViewResource(
          PERMISSIONS.canViewResource.questions.context,
          user_role
        )
      )
        navigate(OrdersRoute.path);
    }
  }, [navigate, user_role]);

  return (
    <Layout>
      <div className={`${theme}`}>
        <p className="">Questions!</p>
      </div>
    </Layout>
  );
};

export default Questions;
