import React from "react";
import "./index.scss";

type Props = {
  children: any;
  onClick: any;
  className?: string;
};

const IconButton: React.FC<Props> = ({ children, onClick, className }) => {
  return (
    <div
      className={`icon_button_container card ${className ? className : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default IconButton;
