import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  getOrders,
  getOrderDetails,
  assignOrder,
  searchOrders,
  getMyAssignedOrders,
  confirmOrDeclineOrder,
  updateOrderStatus,
  createOrderInvoice,
} from "./actions";
import { IAssignedOrders, IOrder, IOrderDetail } from "../../types/order";

export type OrderState = {
  orders: {
    error: string | null;
    loading: boolean;
    data: IOrder[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
  myAssignedOrders: {
    error: string | null;
    loading: boolean;
    data: IAssignedOrders[];
    meta: {
      pages: number;
      page: number;
      total: number;
    };
  };
  confirmOrDeclineOrder: {
    error: string | null;
    loading: boolean;
    message: string;
  };
  createOrderInvoice: {
    error: string | null;
    loading: boolean;
    data: {
      redirect_to_stripe_link: string;
    };
  };
  updateOrderStatus: {
    error: string | null;
    loading: boolean;
    message: string;
    data: {
      id: number;
      status: string;
      amount?: number;
    };
  };
  orderDetails: {
    error: string | null;
    loading: boolean;
    data: IOrderDetail;
  };
  assignOrder: {
    error: string | null;
    loading: boolean;
    message: string;
  };
};

const initialState: OrderState = {
  orders: {
    error: null,
    loading: false,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
  myAssignedOrders: {
    error: null,
    loading: false,
    data: [],
    meta: {
      pages: 0,
      page: 0,
      total: 0,
    },
  },
  confirmOrDeclineOrder: {
    error: null,
    loading: false,
    message: "",
  },
  createOrderInvoice: {
    error: null,
    loading: false,
    data: {
      redirect_to_stripe_link: "",
    },
  },
  updateOrderStatus: {
    error: null,
    loading: false,
    message: "",
    data: {
      id: -1,
      status: "",
      amount: -1,
    },
  },
  orderDetails: {
    error: null,
    loading: false,
    data: {
      items: [],
      orderedBy: undefined,
      assignedTo: undefined,
    },
  },
  assignOrder: {
    error: null,
    loading: false,
    message: "",
  },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetAssignOrderState: (state) => {
      state.assignOrder = initialState.assignOrder;
    },
    resetConfirmOrDeclineOrderState: (state) => {
      state.confirmOrDeclineOrder = initialState.confirmOrDeclineOrder;
    },
    removeOrderFromList: (state, { payload }) => {
      state.myAssignedOrders = {
        ...state.myAssignedOrders,
        data: state.myAssignedOrders.data.filter(
          ({ order }) => order.id !== payload
        ),
      };
    },
    resetUpdateOrderState: (state) => {
      state.updateOrderStatus = initialState.updateOrderStatus;
    },
    updateOrderItem: (state, { payload: { id, unit } }) => {
      const foundOrder = state.orderDetails.data.items.findIndex(
        (item) => Number(item.id) === Number(id)
      );

      if (foundOrder !== -1) {
        state.orderDetails.data.items[foundOrder].unit = unit;
        state.orderDetails.data.items[foundOrder].amount =
          state.orderDetails.data.items[foundOrder].product.price * unit;
      }
    },
  },
  extraReducers: (builder) => {
    // Get Orders
    builder
      .addCase(getOrders.pending, (state) => {
        state.orders.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        state.orders.loading = false;
        state.orders.error = null;
        state.orders.data = payload.data;
        state.orders.meta = payload.meta;
      })
      .addCase(getOrders.rejected, (state, { payload }) => {
        state.orders.loading = false;
        state.orders.error = payload as string;
        state.orders.data = initialState.orders.data;
        state.orders.meta = initialState.orders.meta;
      });

    // Get My Assigned Orders
    builder
      .addCase(getMyAssignedOrders.pending, (state) => {
        state.myAssignedOrders.loading = true;
      })
      .addCase(getMyAssignedOrders.fulfilled, (state, { payload }) => {
        state.myAssignedOrders.loading = false;
        state.myAssignedOrders.error = null;
        state.myAssignedOrders.data = payload.data;
        state.myAssignedOrders.meta = payload.meta;
      })
      .addCase(getMyAssignedOrders.rejected, (state, { payload }) => {
        state.myAssignedOrders.loading = false;
        state.myAssignedOrders.error = payload as string;
        state.myAssignedOrders.data = initialState.myAssignedOrders.data;
        state.myAssignedOrders.meta = initialState.myAssignedOrders.meta;
      });

    // Get Order Details
    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.orderDetails.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, { payload }) => {
        state.orderDetails.loading = false;
        state.orderDetails.error = null;
        state.orderDetails.data = payload;
      })
      .addCase(getOrderDetails.rejected, (state, { payload }) => {
        state.orderDetails.loading = false;
        state.orderDetails.error = payload as string;
      });

    // Assign Order
    builder
      .addCase(assignOrder.pending, (state) => {
        state.assignOrder.loading = true;
      })
      .addCase(assignOrder.fulfilled, (state, { payload }) => {
        state.assignOrder.loading = false;
        state.assignOrder.error = null;
        state.assignOrder.message = payload;
      })
      .addCase(assignOrder.rejected, (state, { payload }) => {
        state.assignOrder.loading = false;
        state.assignOrder.error = payload as string;
      });

    // Confirm or Decline Order
    builder
      .addCase(confirmOrDeclineOrder.pending, (state) => {
        state.confirmOrDeclineOrder.loading = true;
      })
      .addCase(confirmOrDeclineOrder.fulfilled, (state, { payload }) => {
        state.confirmOrDeclineOrder.loading = false;
        state.confirmOrDeclineOrder.error = null;
        state.confirmOrDeclineOrder.message = payload;
      })
      .addCase(confirmOrDeclineOrder.rejected, (state, { payload }) => {
        state.confirmOrDeclineOrder.loading = false;
        state.confirmOrDeclineOrder.error = payload as string;
      });

    // Create Order Invoice
    builder
      .addCase(createOrderInvoice.pending, (state) => {
        state.createOrderInvoice.loading = true;
      })
      .addCase(createOrderInvoice.fulfilled, (state, { payload }) => {
        state.createOrderInvoice.loading = false;
        state.createOrderInvoice.error = null;
        state.createOrderInvoice.data = payload;
      })
      .addCase(createOrderInvoice.rejected, (state, { payload }) => {
        state.createOrderInvoice.loading = false;
        state.createOrderInvoice.error = payload as string;
      });

    // Update Order Status
    builder
      .addCase(updateOrderStatus.pending, (state) => {
        state.updateOrderStatus.loading = true;
      })
      .addCase(updateOrderStatus.fulfilled, (state, { payload }) => {
        state.updateOrderStatus.loading = false;
        state.updateOrderStatus.error = null;
        state.updateOrderStatus.message = payload.message;
        state.updateOrderStatus.data = payload.data;
      })
      .addCase(updateOrderStatus.rejected, (state, { payload }) => {
        state.updateOrderStatus.loading = false;
        state.updateOrderStatus.error = payload as string;
      });

    // Search Orders
    builder
      .addCase(searchOrders.pending, (state) => {
        state.orders.loading = true;
        state.orders.error = null;
      })
      .addCase(searchOrders.fulfilled, (state, { payload }) => {
        state.orders.loading = false;
        state.orders.error = null;
        state.orders.data = payload.data;
        state.orders.meta = payload.meta;
      })
      .addCase(searchOrders.rejected, (state, { payload }) => {
        state.orders.loading = false;
        state.orders.error = payload as string;
        state.orders.data = initialState.orders.data;
        state.orders.meta = initialState.orders.meta;
      });
  },
});

export const selectOrder = (state: RootState) => state.order;

export const {
  resetAssignOrderState,
  resetConfirmOrDeclineOrderState,
  removeOrderFromList,
  resetUpdateOrderState,
  updateOrderItem,
} = orderSlice.actions;

export default orderSlice.reducer;
